
import { SearchParamsEmpty } from '@/shared/model/searchParams';
import { TblUpdateDocumentState } from './types';

export const state = (): TblUpdateDocumentState => initialState();

export const initialState = (): TblUpdateDocumentState => ({
  dashboardDate: undefined,
  dashboardDocumentsStatus: undefined,
  tblUpdateDocuments: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['doc_changedate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  tblUpdateDocumentsWerbung: {
    items: [],
    isLoading: false,
    total: 0, 
    searchParams: SearchParamsEmpty
  },
});
