
import { TblUpdateSupplierState } from './types';
import { SearchParamsEmpty } from '@/shared/model/searchParams';

export const state = (): TblUpdateSupplierState => initialState();

export const initialState = (): TblUpdateSupplierState => ({
  dashboardDate: undefined,
  dashboarCompanyWrGruppen: undefined,
  dashboardSuppliersStatus: undefined,
  tblUpdateSuppliers: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['sp_changedate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  companyWrGruppen: {
    items: [],
    isLoading: undefined,
    total: 0,
    searchParams: SearchParamsEmpty
  }
});
