import { ROUTES } from '@/router/routesEnum';
import axios from 'axios';
import { msalInstance } from 'vue-msal-browser';
import router from '../../router';
import store from '../store';
import { loginApiRequest } from '../utils/authConfig';
import LSService from './LocalStorageService';
const lsService = LSService.getService();

export const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API_URL,
});

export enum URLS {
  authenticate = '/api/Users/login2',
  account = '/api/Users',

  companyOdata = '/odata/companys',
  company = '/api/companys',
  conditionCategoryOdata = '/odata/conditionCategorys',
  conditionCategory = '/api/conditionCategorys',
  contactOdata = '/odata/contacts',
  contact = '/api/contacts',
  contactPanelOdata = '/odata/contactPanels',
  contactPanel = '/api/contactPanels',

  documentTypeOdata = '/odata/documentTypes',
  documentType = '/api/documentTypes',

  gspRoleOdata = '/odata/gspRoles',
  gspRole = '/api/gspRoles',
  gspRoleDataOdata = '/odata/gspRolesData',
  gspRoleData = '/api/gspRolesData',
  gspUserRoleOdata = '/odata/gspUserRoles',
  gspUserRole = '/api/gspUserRoles',
  gspUserRoleDetailOdata = '/odata/gspUserRoleDetails',
  gspUserRoleDetail = '/api/gspUserRoleDetails',


  gspAttributeOdata = '/odata/gspAttributes',
  gspAttribute = '/api/gspAttributes',
  gspModuleOdata = '/odata/gspModules',
  gspModule = '/api/gspModules',
  gspServiceOdata = '/odata/gspServices',
  gspService = '/api/gspServices',

  tblUpdateSupplierOdata = '/odata/tblUpdateSuppliers',
  tblUpdateSupplier = '/api/tblUpdateSuppliers',

  tblUpdateConditionOdata = '/odata/tblUpdateConditions',
  tblUpdateCondition = '/api/tblUpdateConditions',

  tblUpdateDocumentOdata = '/odata/tblUpdateDocuments',
  tblUpdateDocument = '/api/tblUpdateDocuments',

  // TODO(ad-105) fix console error no need for this in frontend
  userActionsUserRoleChangesOdata='/odata/UserActionsUserRoleChanges',
  userActionsUserRoleChanges='/api/UserActionsUserRoleChanges',

  users = 'api/users',
  usersOdata = 'odata/users',
  version = 'api/version', // for test

  msGraphUsers = 'https://graph.microsoft.com/v1.0/users',
}

// Need to ignore MSAL azure token for using these URLs in 3rd-party sites via `iframe`
const routesNoToken = ['GetVmeSupplier','GetVmeUserIdByEmail','GetCompaniesIframe', 'GetEnvironmentName'];
// (AD-161) new routes
routesNoToken.push('odata/tblUpdateSuppliers','odata/tblUpdateConditions', 'odata/tblUpdateDocuments','companys/getUserCompanyZr', 'GetCompanyWrGruppen','GetConditionsVisible');
routesNoToken.push('/api/tblUpdateConditions/AddConditionCompleted','/api/tblUpdateDocuments/AddDocumentCompleted','/api/tblUpdateSuppliers/AddSupplierCompleted','CanselConditionCompleted','CanselDocumentCompleted','CanselSupplierCompleted');

instance.interceptors.request.use(
  async (config: any) => {
    try {
      console.log('config :>> ', config);
      // not get token only for `GetVmeSupplier` and `GetVmeUserIdByEmail` endpoints
      const url = (config?.url + '').toLowerCase();
      // url?.includes('GetVmeSupplier') || url?.includes('GetVmeUserIdByEmail')
      if (routesNoToken.some(route => url.includes(route.toLowerCase()))) {
        config.headers['Content-Type'] = 'application/json';
        // console.log('config?.url config?.url:>> NO TOKIN NOT TOKEN ', config?.url);
        return config;
      }

      console.log('try to send interceptors:>> ', );
      // console.log('config interceptors.request.use :>> ', config);
      let account = msalInstance!.getAllAccounts()[0]; //.getAllAccounts()[0];
      // const account2 = msalInstance!.getAllAccounts(); //.getAllAccounts()[0];
      // console.log('account dd:>> ', account );
      // console.log('account dd:>> ', account2 );
      // console.log('msalInstance :>> ', msalInstance);
      if (!account) {
        setTimeout( async () => {
          account=msalInstance!.getAllAccounts()[0];
          return await setupConfigWithToken(account, config);
        }, 2000);
        // return config;
      } else {
        return await setupConfigWithToken(account, config);
      }
    } catch (error) {
      // https://github.com/svrcekmichal/redux-axios-middleware/issues/83
      console.error('backend index Error, config URLll:>> ', config?.url);
      console.error(error);
    }
  },
  (error: any) => {
    // store.dispatch(`${URLS.users}/logout`);
    // router.push({
    //   name: ROUTES.login,
    //   params: { checkLogin: 'false' },
    // });
  }
);
async function setupConfigWithToken(account:any, config: any) {
  const msalResponse = await msalInstance?.acquireTokenSilent({
    ...loginApiRequest,
    account: account,
  });
  const tokenMsGraphApi = await msalInstance!.acquireTokenSilent({
    scopes: ['https://graph.microsoft.com/User.Read'],
    account: account,
  });
  // const token = lsService.getAccessToken(); // don't need it for AZURE_AD // TODO: find a way to distinguish between Azure and usual login if needed or remove usual `login/pass` logic
  if (msalResponse && msalResponse.accessToken) {
    config.headers['Authorization'] = 'Bearer ' + msalResponse!.accessToken;
  }
  if (config.url.includes('graph.microsoft.com/v1.0')) {
    config.headers['Authorization'] = 'Bearer ' + tokenMsGraphApi.accessToken;
    config.headers['ConsistencyLevel'] = 'eventual'; // needed for OData `$search` query operator
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
}


instance.interceptors.response.use(
  (response: any) => {
    // console.log('window.location.pathName RESPONSE :>> ', window.location.pathname);

    return response;
  },
  (error: any) => {
    const originalRequest = error.config;
    const refreshToken = lsService.getRefreshToken();
    if (error.response?.status === 401 && !originalRequest._retry && refreshToken) {
      originalRequest._retry = true;

      const token = lsService.getAccessToken();
      return axios
        .post(
          `${URLS.users}/refreshtoken`,
          {
            refreshToken: refreshToken,
            accessToken: token,
          },
          {
            headers: {
              'Content-Type': 'application/json', // TODO: test if need this header (backend was set up to use JSON) + the same for `checkPasswordReset` action
            },
          }
        )
        .then((res: any) => {
          if (res?.status === 201 || res?.status == 200) {
            lsService.setToken(res.data);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + lsService.getAccessToken();
            return axios(originalRequest);
          }

          return Promise.reject(error);
        });
    } else if (error.response?.status === 401) {
      // NO refresh Token (not checked `Remember me` on Login) and JWT token expired

      store.commit('auth/resetAccount', undefined); // (EGRUP-270) need this reset to make `isLoggedIn` getter FALSE, otherwise we will see left drawer menu with icons and Username in top left corner
      // router.push({
      //   name: ROUTES.login,
      //   params: { checkLogin: 'false' },
      // });
      // tried to show `'Der Token ist abgelaufen. Bitte erneut anmelden',` snackbar but it always overriden by `"Laden der Daten fehlgeschlagen` red snackbar from `action.ts`
    }
    throw error;
  }
);
