
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import contactPanel, { ContactPanel, Value } from '../model/contactPanel';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ContactPanel');
export interface BackendContactPanel {
  getContactPanel(email: string): AxiosPromise<ContactPanel>;
  getContactPanels: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteContactPanel(id: string): AxiosPromise;
  updateContactPanel(ContactPanel: ContactPanel): AxiosPromise<any>;
  updateContactPanelKatalogwand(ContactPanel: ContactPanel): AxiosPromise<any>;
}

export const defaultBackendContactPanel: BackendContactPanel = {
  getContactPanel(email: string): AxiosPromise<ContactPanel> {
    const url = `${URLS.contactPanelOdata}/${email}`;
    return instance.get<ContactPanel>(url);
  },
  getContactPanels(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.contactPanelOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getContactPanels${url}`);
    return instance.get<Value>(url);
  },

  deleteContactPanel(id: string): AxiosPromise {
    logger.debug('deleteContactPanel');
    return instance.delete(`${URLS.contactPanel}/${id}`);
  },
  updateContactPanel(ContactPanel: ContactPanel): AxiosPromise<any> {
    logger.debug('updateContactPanel');
    return instance.put<ContactPanel>(`${URLS.contactPanel}/update`, contactPanel.toAPI(ContactPanel));
  },
  updateContactPanelKatalogwand(ContactPanel: ContactPanel): AxiosPromise<any> {
    logger.debug('updateContactPanelKatalogwand');
    return instance.put<ContactPanel>(`${URLS.contactPanel}/updateKatalogwand`, contactPanel.toAPI(ContactPanel));
  },
};
