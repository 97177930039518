import { GetterTree } from 'vuex';
import { GspRoleDataState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<GspRoleDataState, RootState> = {
  ['getGspRolesData']: (state) => state.gspRolesData,
  ['getGspRolesDataIsLoading']: (state) => state.gspRolesData?.isLoading,
  ['getGspRolesDataTotal']: (state) => state.gspRolesData?.total,
  ['getGspRolesDataSearchParams']: (state) => state.gspRolesData?.searchParams,
};
