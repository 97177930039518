export interface Company {
  recordID: string; // ulong
  suchname2: string; // string
  kurzwahl: string; // string
  targetAccount: string; // string
  postfach: string; // string
  rolleUnternehmenInterneIT: string; // string
  lastModifiedDate: string; // System.DateTime
  leadStatus: string; // string
  totalRevenue: number; // double?
  postalCode: string; // string
  rolleMarkenportfolioInterliving: string; // string
  hauptwarengruppen: string; // string
  steuerNr: string; // string
  twitterFollowers: string; // string
  unternehmenjetztduplizieren: string; // string
  rolleUnternehmenGruppe: string; // string
  companyDomainName: string; // string
  lastTouchConvertingCampaign: string; // string
  firstTouchConvertingCampaign: string; // string
  recentDealCloseDate: string; // System.DateTime?
  numberofPageviews: number; // int?
  numberofEmployees: number; // int?
  campaignoflastbookinginmeetingstool: string; // string
  neuerZRStatus: string; // string
  numberofDecisionmakers: number; // int?
  zustaendigerEinkaeufer: string; // string
  zRVerrechnungueber: string; // string
  timeofLastSession: string; // System.DateTime?
  timeofFirstSession: string; // System.DateTime?
  numberofblockers: number; // int
  closeDate: string; // System.DateTime?
  idealCustomerProfileTier: string; // string
  iBAN: string; // string
  facebookFans: string; // string
  latestSource: string; // string
  numberofAssociatedDeals: number; // int?
  lastLoggedCallDate: string; // System.DateTime?
  unternehmensnameZUSATZ: string; // string
  recentDealAmount: number; // double?
  numberoftimescontacted: number; // int?
  uST_IDNr: string; // string
  firstConversionDate: string; // System.DateTime?
  lastOpenTaskDate: string; // System.DateTime?
  originalSourceType: string; // string
  umsatzanteilHWGRSchlafzimmer: number; // int?
  gLN: string; // string
  firstDealCreatedDate: string; // System.DateTime?
  numberofFormSubmissions: number; // int?
  lieferstrasse: string; // string
  mediumoflastbookinginmeetingstool: string; // string
  facebookCompanyPage: string; // string
  createDate: string; // System.DateTime
  linkedInBio: string; // string
  firstConversion: string; // string
  lieferPLZ: string; // string
  dateoflastmeetingbookedinmeetingstool: string; // System.DateTime?
  umsatzanteilHWGRKuechen: number; // int?
  rolleITSystememeinVMEGenehmigungen: string; // string
  umsatzanteilHWGRFachsortimente: number; // int?
  city: string; // string
  companyname: string; // string
  numberofchildcompanies: number; // int
  zustaendigerDKAM: string; // string
  taipanUnternehmen: string; // string
  bankinstitut: string; // string
  zRBeginn: string; // System.DateTime?
  umsatzanteilHWGRWohnzimmer: number; // int?
  totalopendealvalue: string; // string
  zROffboardingStatus: string; // string
  numberofSessions: number; // int?
  phoneNumber: string; // string
  bankOrt: string; // string
  lieferantengruppen: string; // string
  lastEngagementDate: string; // System.DateTime?
  zRBemerkungenzuDebitor_INTERN: string; // string
  rolleUnternehmenVMEZugehoerigkeit_Sonderfaelle: string; // string
  companyowner: string; // string
  rolleMarkenportfolioHandelsmarken: string; // string
  aboutUs: string; // string
  lastActivityDate: string; // System.DateTime?
  nextActivityDate: string; // System.DateTime?
  umsatzanteilSpeisezimmer_Tisch_Eckbankgr: number; // int?
  rolleUnternehmenWerbegemeinschaft: string; // string
  sourceoflastbookinginmeetingstool: string; // string
  ownerAssignedDate: string; // System.DateTime?
  stateRegion: string; // string
  umsatzanteilHWGRKleinmoebel: number; // int?
  zRBemerkungenzuDebitor_EXTERN: string; // string
  zREnde: string; // System.DateTime?
  postfachOrt: string; // string
  iLDashboardfreigabeberechtigtePersonmeinVMEGenehmigungen: string; // string
  linkedInCompanyPage: string; // string
  totalMoneyRaised: number; // double?
  createdbyuserID: string; // string
  rechnungsformat: string; // string
  numberofAssociatedContacts: number; // int
  originalSourceData1: string; // string
  telefax: string; // string
  recentConversionDate: string; // System.DateTime?
  digitaleProdukteServicebeschreibungaccepted: string; // string
  numberofcontactswithabuyingrole: number; // int
  originalSourceData2: string; // string
  lifecycleStage: string; // string
  lastBookedMeetingDate: string; // System.DateTime?
  lastContacted: string; // System.DateTime?
  streetAddress: string; // string
  recentConversion: string; // string
  hubSpotTeam: string; // string
  twitterBio: string; // string
  webTechnologies: string; // string
  rolleUnternehmenWerbung: string; // string
  latestSourceData1: string; // string
  rolleITSystemeWarenwirtschaft: string; // string
  likelihoodtoclose: number; // double?
  zRLieferRechnungAnschrift: string; // string
  latestSourceData2: string; // string
  countryRegion: string; // string
  eMailAdresseFirma: string; // string
  fPPostfachLaenderKZ: string; // string
  rolleUnternehmenVerteilergruppen: string; // string
  firstContactCreateDate: string; // System.DateTime?
  timeZone: string; // string
  zRMasterNR: string; // string
  rolleUnternehmenZRBank: string; // string
  rolleUnternehmenBetriebsvergleich: string; // string
  timeLastSeen: string; // System.DateTime?
  timeFirstSeen: string; // System.DateTime?
  rolleITSystemeSonstige: string; // string
  waehrung: string; // string
  type: string; // string
  rolleUnternehmenVMEZugehörigkeit: string; // string
  websiteURL: string; // string
  infobis: string; // System.DateTime?
  yearFounded: string; // ushort?
  twitterHandle: string; // string
  umsatzanteilHWGRTeppiche: number; // int?
  numberofassociatedFurnplan: number; // int
  zRNummer: string; // string
  googlePlusPage: string; // string
  rolleUnternehmenUmsatzvergleich: string; // string
  bIC: string; // string
  iLDashboardAzureServiceID: string; // string
  umsatzanteilHWGRRattanuGartenmoebel: number; // int?
  daystoClose: number; // int?
  description: string; // string
  lieferOrt: string; // string
  annualRevenue: number; // double?
  lieferstrasse2: string; // string
  latestSourceTimestamp: string; // System.DateTime?
  parentCompany: string; // ulong?
  industry: string; // string
  objectcreateDateTime: string; // System.DateTime?
  streetAddress2: string; // string
  postfachPLZ: string; // string
  isPublic: false; // bool?
  suchname1: string; // string
  umsatzanteilDiverseZRLieferanten: number; // int?
  lieferLand: string; // string
  tEMP_Unternehmensbefragung: string; // string
  numberofopendeals: number; // int
  umsatzanteilHWGRPolstermöbel: number; // int?
  associatedContact: string; // string
  associatedDeal: string; // string
  associatedTicket: string; // string
  associatedPayment: string; // string
  associatedSubscription: string; // string
  additionalDomains: string; // string
  associatedContactIDs: string; // string
  associatedDealIDs: string; // string
  associatedTicketIDs: string; // string
  associatedPaymentIDs: string; // string
  associatedSubscriptionIDs: string; // string
}

export interface CompanyZrResult {
  companyName: string;
  zrNummer: string;
}

export interface CompanyWrGruppenResult {
  hauptwarengruppen: string;
  zrNummer: string;
}
export interface Value {
  value: Company[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Company>): any {
  return {
    RecordID: data?.recordID || undefined,
    Suchname2: data?.suchname2 || '',
    Kurzwahl: data?.kurzwahl || '',
    TargetAccount: data?.targetAccount || '',
    Postfach: data?.postfach || '',
    RolleUnternehmenInterneIT: data?.rolleUnternehmenInterneIT || '',
    LastModifiedDate: data?.lastModifiedDate || '',
    LeadStatus: data?.leadStatus || '',
    TotalRevenue: data?.totalRevenue || 0,
    PostalCode: data?.postalCode || '',
    RolleMarkenportfolioInterliving: data?.rolleMarkenportfolioInterliving || '',
    Hauptwarengruppen: data?.hauptwarengruppen || '',
    SteuerNr: data?.steuerNr || '',
    TwitterFollowers: data?.twitterFollowers || '',
    Unternehmenjetztduplizieren: data?.unternehmenjetztduplizieren || '',
    RolleUnternehmenGruppe: data?.rolleUnternehmenGruppe || '',
    CompanyDomainName: data?.companyDomainName || '',
    LastTouchConvertingCampaign: data?.lastTouchConvertingCampaign || '',
    FirstTouchConvertingCampaign: data?.firstTouchConvertingCampaign || '',
    RecentDealCloseDate: data?.recentDealCloseDate || '',
    NumberofPageviews: data?.numberofPageviews || 0,
    NumberofEmployees: data?.numberofEmployees || 0,
    Campaignoflastbookinginmeetingstool: data?.campaignoflastbookinginmeetingstool || '',
    NeuerZRStatus: data?.neuerZRStatus || '',
    NumberofDecisionmakers: data?.numberofDecisionmakers || 0,
    ZustaendigerEinkaeufer: data?.zustaendigerEinkaeufer || '',
    ZRVerrechnungueber: data?.zRVerrechnungueber || '',
    TimeofLastSession: data?.timeofLastSession || '',
    TimeofFirstSession: data?.timeofFirstSession || '',
    Numberofblockers: data?.numberofblockers || 0,
    CloseDate: data?.closeDate || '',
    IdealCustomerProfileTier: data?.idealCustomerProfileTier || '',
    IBAN: data?.iBAN || '',
    FacebookFans: data?.facebookFans || '',
    LatestSource: data?.latestSource || '',
    NumberofAssociatedDeals: data?.numberofAssociatedDeals || 0,
    LastLoggedCallDate: data?.lastLoggedCallDate || '',
    UnternehmensnameZUSATZ: data?.unternehmensnameZUSATZ || '',
    RecentDealAmount: data?.recentDealAmount || 0,
    Numberoftimescontacted: data?.numberoftimescontacted || 0,
    UST_IDNr: data?.uST_IDNr || '',
    FirstConversionDate: data?.firstConversionDate || '',
    LastOpenTaskDate: data?.lastOpenTaskDate || '',
    OriginalSourceType: data?.originalSourceType || '',
    UmsatzanteilHWGRSchlafzimmer: data?.umsatzanteilHWGRSchlafzimmer || 0,
    GLN: data?.gLN || '',
    FirstDealCreatedDate: data?.firstDealCreatedDate || '',
    NumberofFormSubmissions: data?.numberofFormSubmissions || 0,
    Lieferstrasse: data?.lieferstrasse || '',
    Mediumoflastbookinginmeetingstool: data?.mediumoflastbookinginmeetingstool || '',
    FacebookCompanyPage: data?.facebookCompanyPage || '',
    CreateDate: data?.createDate || '',
    LinkedInBio: data?.linkedInBio || '',
    FirstConversion: data?.firstConversion || '',
    LieferPLZ: data?.lieferPLZ || '',
    Dateoflastmeetingbookedinmeetingstool: data?.dateoflastmeetingbookedinmeetingstool || '',
    UmsatzanteilHWGRKuechen: data?.umsatzanteilHWGRKuechen || 0,
    RolleITSystememeinVMEGenehmigungen: data?.rolleITSystememeinVMEGenehmigungen || '',
    UmsatzanteilHWGRFachsortimente: data?.umsatzanteilHWGRFachsortimente || 0,
    City: data?.city || '',
    Companyname: data?.companyname || '',
    Numberofchildcompanies: data?.numberofchildcompanies || 0,
    ZustaendigerDKAM: data?.zustaendigerDKAM || '',
    TaipanUnternehmen: data?.taipanUnternehmen || '',
    Bankinstitut: data?.bankinstitut || '',
    ZRBeginn: data?.zRBeginn || '',
    UmsatzanteilHWGRWohnzimmer: data?.umsatzanteilHWGRWohnzimmer || 0,
    Totalopendealvalue: data?.totalopendealvalue || '',
    ZROffboardingStatus: data?.zROffboardingStatus || '',
    NumberofSessions: data?.numberofSessions || 0,
    PhoneNumber: data?.phoneNumber || '',
    BankOrt: data?.bankOrt || '',
    Lieferantengruppen: data?.lieferantengruppen || '',
    LastEngagementDate: data?.lastEngagementDate || '',
    ZRBemerkungenzuDebitor_INTERN: data?.zRBemerkungenzuDebitor_INTERN || '',
    RolleUnternehmenVMEZugehoerigkeit_Sonderfaelle: data?.rolleUnternehmenVMEZugehoerigkeit_Sonderfaelle || '',
    Companyowner: data?.companyowner || '',
    RolleMarkenportfolioHandelsmarken: data?.rolleMarkenportfolioHandelsmarken || '',
    AboutUs: data?.aboutUs || '',
    LastActivityDate: data?.lastActivityDate || '',
    NextActivityDate: data?.nextActivityDate || '',
    UmsatzanteilSpeisezimmer_Tisch_Eckbankgr: data?.umsatzanteilSpeisezimmer_Tisch_Eckbankgr || 0,
    RolleUnternehmenWerbegemeinschaft: data?.rolleUnternehmenWerbegemeinschaft || '',
    Sourceoflastbookinginmeetingstool: data?.sourceoflastbookinginmeetingstool || '',
    OwnerAssignedDate: data?.ownerAssignedDate || '',
    StateRegion: data?.stateRegion || '',
    UmsatzanteilHWGRKleinmoebel: data?.umsatzanteilHWGRKleinmoebel || 0,
    ZRBemerkungenzuDebitor_EXTERN: data?.zRBemerkungenzuDebitor_EXTERN || '',
    ZREnde: data?.zREnde || '',
    PostfachOrt: data?.postfachOrt || '',
    ILDashboardfreigabeberechtigtePersonmeinVMEGenehmigungen:
      data?.iLDashboardfreigabeberechtigtePersonmeinVMEGenehmigungen || '',
    LinkedInCompanyPage: data?.linkedInCompanyPage || '',
    TotalMoneyRaised: data?.totalMoneyRaised || 0,
    CreatedbyuserID: data?.createdbyuserID || '',
    Rechnungsformat: data?.rechnungsformat || '',
    NumberofAssociatedContacts: data?.numberofAssociatedContacts || 0,
    OriginalSourceData1: data?.originalSourceData1 || '',
    Telefax: data?.telefax || '',
    RecentConversionDate: data?.recentConversionDate || '',
    DigitaleProdukteServicebeschreibungaccepted: data?.digitaleProdukteServicebeschreibungaccepted || '',
    Numberofcontactswithabuyingrole: data?.numberofcontactswithabuyingrole || 0,
    OriginalSourceData2: data?.originalSourceData2 || '',
    LifecycleStage: data?.lifecycleStage || '',
    LastBookedMeetingDate: data?.lastBookedMeetingDate || '',
    LastContacted: data?.lastContacted || '',
    StreetAddress: data?.streetAddress || '',
    RecentConversion: data?.recentConversion || '',
    HubSpotTeam: data?.hubSpotTeam || '',
    TwitterBio: data?.twitterBio || '',
    WebTechnologies: data?.webTechnologies || '',
    RolleUnternehmenWerbung: data?.rolleUnternehmenWerbung || '',
    LatestSourceData1: data?.latestSourceData1 || '',
    RolleITSystemeWarenwirtschaft: data?.rolleITSystemeWarenwirtschaft || '',
    Likelihoodtoclose: data?.likelihoodtoclose || 0,
    ZRLieferRechnungAnschrift: data?.zRLieferRechnungAnschrift || '',
    LatestSourceData2: data?.latestSourceData2 || '',
    CountryRegion: data?.countryRegion || '',
    EMailAdresseFirma: data?.eMailAdresseFirma || '',
    FPPostfachLaenderKZ: data?.fPPostfachLaenderKZ || '',
    RolleUnternehmenVerteilergruppen: data?.rolleUnternehmenVerteilergruppen || '',
    FirstContactCreateDate: data?.firstContactCreateDate || '',
    TimeZone: data?.timeZone || '',
    ZRMasterNR: data?.zRMasterNR || '',
    RolleUnternehmenZRBank: data?.rolleUnternehmenZRBank || '',
    RolleUnternehmenBetriebsvergleich: data?.rolleUnternehmenBetriebsvergleich || '',
    TimeLastSeen: data?.timeLastSeen || '',
    TimeFirstSeen: data?.timeFirstSeen || '',
    RolleITSystemeSonstige: data?.rolleITSystemeSonstige || '',
    Waehrung: data?.waehrung || '',
    Type: data?.type || '',
    RolleUnternehmenVMEZugehörigkeit: data?.rolleUnternehmenVMEZugehörigkeit || '',
    WebsiteURL: data?.websiteURL || '',
    Infobis: data?.infobis || '',
    YearFounded: data?.yearFounded || '',
    TwitterHandle: data?.twitterHandle || '',
    UmsatzanteilHWGRTeppiche: data?.umsatzanteilHWGRTeppiche || 0,
    NumberofassociatedFurnplan: data?.numberofassociatedFurnplan || 0,
    ZRNummer: data?.zRNummer || '',
    GooglePlusPage: data?.googlePlusPage || '',
    RolleUnternehmenUmsatzvergleich: data?.rolleUnternehmenUmsatzvergleich || '',
    BIC: data?.bIC || '',
    ILDashboardAzureServiceID: data?.iLDashboardAzureServiceID || '',
    UmsatzanteilHWGRRattanuGartenmoebel: data?.umsatzanteilHWGRRattanuGartenmoebel || 0,
    DaystoClose: data?.daystoClose || 0,
    Description: data?.description || '',
    LieferOrt: data?.lieferOrt || '',
    AnnualRevenue: data?.annualRevenue || 0,
    Lieferstrasse2: data?.lieferstrasse2 || '',
    LatestSourceTimestamp: data?.latestSourceTimestamp || '',
    ParentCompany: data?.parentCompany || '',
    Industry: data?.industry || '',
    ObjectcreateDateTime: data?.objectcreateDateTime || '',
    StreetAddress2: data?.streetAddress2 || '',
    PostfachPLZ: data?.postfachPLZ || '',
    IsPublic: data?.isPublic || false,
    Suchname1: data?.suchname1 || '',
    UmsatzanteilDiverseZRLieferanten: data?.umsatzanteilDiverseZRLieferanten || 0,
    LieferLand: data?.lieferLand || '',
    TEMP_Unternehmensbefragung: data?.tEMP_Unternehmensbefragung || '',
    Numberofopendeals: data?.numberofopendeals || 0,
    UmsatzanteilHWGRPolstermöbel: data?.umsatzanteilHWGRPolstermöbel || 0,
    AssociatedContact: data?.associatedContact || '',
    AssociatedDeal: data?.associatedDeal || '',
    AssociatedTicket: data?.associatedTicket || '',
    AssociatedPayment: data?.associatedPayment || '',
    AssociatedSubscription: data?.associatedSubscription || '',
    AdditionalDomains: data?.additionalDomains || '',
    AssociatedContactIDs: data?.associatedContactIDs || '',
    AssociatedDealIDs: data?.associatedDealIDs || '',
    AssociatedTicketIDs: data?.associatedTicketIDs || '',
    AssociatedPaymentIDs: data?.associatedPaymentIDs || '',
    AssociatedSubscriptionIDs: data?.associatedSubscriptionIDs || '',
  };
}

function parse(data?: Partial<Company>): Company {
  return {
    recordID: data?.recordID || '',
    suchname2: data?.suchname2 || '',
    kurzwahl: data?.kurzwahl || '',
    targetAccount: data?.targetAccount || '',
    postfach: data?.postfach || '',
    rolleUnternehmenInterneIT: data?.rolleUnternehmenInterneIT || '',
    lastModifiedDate: data?.lastModifiedDate || '',
    leadStatus: data?.leadStatus || '',
    totalRevenue: data?.totalRevenue || 0,
    postalCode: data?.postalCode || '',
    rolleMarkenportfolioInterliving: data?.rolleMarkenportfolioInterliving || '',
    hauptwarengruppen: data?.hauptwarengruppen || '',
    steuerNr: data?.steuerNr || '',
    twitterFollowers: data?.twitterFollowers || '',
    unternehmenjetztduplizieren: data?.unternehmenjetztduplizieren || '',
    rolleUnternehmenGruppe: data?.rolleUnternehmenGruppe || '',
    companyDomainName: data?.companyDomainName || '',
    lastTouchConvertingCampaign: data?.lastTouchConvertingCampaign || '',
    firstTouchConvertingCampaign: data?.firstTouchConvertingCampaign || '',
    recentDealCloseDate: data?.recentDealCloseDate || '',
    numberofPageviews: data?.numberofPageviews || 0,
    numberofEmployees: data?.numberofEmployees || 0,
    campaignoflastbookinginmeetingstool: data?.campaignoflastbookinginmeetingstool || '',
    neuerZRStatus: data?.neuerZRStatus || '',
    numberofDecisionmakers: data?.numberofDecisionmakers || 0,
    zustaendigerEinkaeufer: data?.zustaendigerEinkaeufer || '',
    zRVerrechnungueber: data?.zRVerrechnungueber || '',
    timeofLastSession: data?.timeofLastSession || '',
    timeofFirstSession: data?.timeofFirstSession || '',
    numberofblockers: data?.numberofblockers || 0,
    closeDate: data?.closeDate || '',
    idealCustomerProfileTier: data?.idealCustomerProfileTier || '',
    iBAN: data?.iBAN || '',
    facebookFans: data?.facebookFans || '',
    latestSource: data?.latestSource || '',
    numberofAssociatedDeals: data?.numberofAssociatedDeals || 0,
    lastLoggedCallDate: data?.lastLoggedCallDate || '',
    unternehmensnameZUSATZ: data?.unternehmensnameZUSATZ || '',
    recentDealAmount: data?.recentDealAmount || 0,
    numberoftimescontacted: data?.numberoftimescontacted || 0,
    uST_IDNr: data?.uST_IDNr || '',
    firstConversionDate: data?.firstConversionDate || '',
    lastOpenTaskDate: data?.lastOpenTaskDate || '',
    originalSourceType: data?.originalSourceType || '',
    umsatzanteilHWGRSchlafzimmer: data?.umsatzanteilHWGRSchlafzimmer || 0,
    gLN: data?.gLN || '',
    firstDealCreatedDate: data?.firstDealCreatedDate || '',
    numberofFormSubmissions: data?.numberofFormSubmissions || 0,
    lieferstrasse: data?.lieferstrasse || '',
    mediumoflastbookinginmeetingstool: data?.mediumoflastbookinginmeetingstool || '',
    facebookCompanyPage: data?.facebookCompanyPage || '',
    createDate: data?.createDate || '',
    linkedInBio: data?.linkedInBio || '',
    firstConversion: data?.firstConversion || '',
    lieferPLZ: data?.lieferPLZ || '',
    dateoflastmeetingbookedinmeetingstool: data?.dateoflastmeetingbookedinmeetingstool || '',
    umsatzanteilHWGRKuechen: data?.umsatzanteilHWGRKuechen || 0,
    rolleITSystememeinVMEGenehmigungen: data?.rolleITSystememeinVMEGenehmigungen || '',
    umsatzanteilHWGRFachsortimente: data?.umsatzanteilHWGRFachsortimente || 0,
    city: data?.city || '',
    companyname: data?.companyname || '',
    numberofchildcompanies: data?.numberofchildcompanies || 0,
    zustaendigerDKAM: data?.zustaendigerDKAM || '',
    taipanUnternehmen: data?.taipanUnternehmen || '',
    bankinstitut: data?.bankinstitut || '',
    zRBeginn: data?.zRBeginn || '',
    umsatzanteilHWGRWohnzimmer: data?.umsatzanteilHWGRWohnzimmer || 0,
    totalopendealvalue: data?.totalopendealvalue || '',
    zROffboardingStatus: data?.zROffboardingStatus || '',
    numberofSessions: data?.numberofSessions || 0,
    phoneNumber: data?.phoneNumber || '',
    bankOrt: data?.bankOrt || '',
    lieferantengruppen: data?.lieferantengruppen || '',
    lastEngagementDate: data?.lastEngagementDate || '',
    zRBemerkungenzuDebitor_INTERN: data?.zRBemerkungenzuDebitor_INTERN || '',
    rolleUnternehmenVMEZugehoerigkeit_Sonderfaelle: data?.rolleUnternehmenVMEZugehoerigkeit_Sonderfaelle || '',
    companyowner: data?.companyowner || '',
    rolleMarkenportfolioHandelsmarken: data?.rolleMarkenportfolioHandelsmarken || '',
    aboutUs: data?.aboutUs || '',
    lastActivityDate: data?.lastActivityDate || '',
    nextActivityDate: data?.nextActivityDate || '',
    umsatzanteilSpeisezimmer_Tisch_Eckbankgr: data?.umsatzanteilSpeisezimmer_Tisch_Eckbankgr || 0,
    rolleUnternehmenWerbegemeinschaft: data?.rolleUnternehmenWerbegemeinschaft || '',
    sourceoflastbookinginmeetingstool: data?.sourceoflastbookinginmeetingstool || '',
    ownerAssignedDate: data?.ownerAssignedDate || '',
    stateRegion: data?.stateRegion || '',
    umsatzanteilHWGRKleinmoebel: data?.umsatzanteilHWGRKleinmoebel || 0,
    zRBemerkungenzuDebitor_EXTERN: data?.zRBemerkungenzuDebitor_EXTERN || '',
    zREnde: data?.zREnde || '',
    postfachOrt: data?.postfachOrt || '',
    iLDashboardfreigabeberechtigtePersonmeinVMEGenehmigungen:
      data?.iLDashboardfreigabeberechtigtePersonmeinVMEGenehmigungen || '',
    linkedInCompanyPage: data?.linkedInCompanyPage || '',
    totalMoneyRaised: data?.totalMoneyRaised || 0,
    createdbyuserID: data?.createdbyuserID || '',
    rechnungsformat: data?.rechnungsformat || '',
    numberofAssociatedContacts: data?.numberofAssociatedContacts || 0,
    originalSourceData1: data?.originalSourceData1 || '',
    telefax: data?.telefax || '',
    recentConversionDate: data?.recentConversionDate || '',
    digitaleProdukteServicebeschreibungaccepted: data?.digitaleProdukteServicebeschreibungaccepted || '',
    numberofcontactswithabuyingrole: data?.numberofcontactswithabuyingrole || 0,
    originalSourceData2: data?.originalSourceData2 || '',
    lifecycleStage: data?.lifecycleStage || '',
    lastBookedMeetingDate: data?.lastBookedMeetingDate || '',
    lastContacted: data?.lastContacted || '',
    streetAddress: data?.streetAddress || '',
    recentConversion: data?.recentConversion || '',
    hubSpotTeam: data?.hubSpotTeam || '',
    twitterBio: data?.twitterBio || '',
    webTechnologies: data?.webTechnologies || '',
    rolleUnternehmenWerbung: data?.rolleUnternehmenWerbung || '',
    latestSourceData1: data?.latestSourceData1 || '',
    rolleITSystemeWarenwirtschaft: data?.rolleITSystemeWarenwirtschaft || '',
    likelihoodtoclose: data?.likelihoodtoclose || 0,
    zRLieferRechnungAnschrift: data?.zRLieferRechnungAnschrift || '',
    latestSourceData2: data?.latestSourceData2 || '',
    countryRegion: data?.countryRegion || '',
    eMailAdresseFirma: data?.eMailAdresseFirma || '',
    fPPostfachLaenderKZ: data?.fPPostfachLaenderKZ || '',
    rolleUnternehmenVerteilergruppen: data?.rolleUnternehmenVerteilergruppen || '',
    firstContactCreateDate: data?.firstContactCreateDate || '',
    timeZone: data?.timeZone || '',
    zRMasterNR: data?.zRMasterNR || '',
    rolleUnternehmenZRBank: data?.rolleUnternehmenZRBank || '',
    rolleUnternehmenBetriebsvergleich: data?.rolleUnternehmenBetriebsvergleich || '',
    timeLastSeen: data?.timeLastSeen || '',
    timeFirstSeen: data?.timeFirstSeen || '',
    rolleITSystemeSonstige: data?.rolleITSystemeSonstige || '',
    waehrung: data?.waehrung || '',
    type: data?.type || '',
    rolleUnternehmenVMEZugehörigkeit: data?.rolleUnternehmenVMEZugehörigkeit || '',
    websiteURL: data?.websiteURL || '',
    infobis: data?.infobis || '',
    yearFounded: data?.yearFounded || '',
    twitterHandle: data?.twitterHandle || '',
    umsatzanteilHWGRTeppiche: data?.umsatzanteilHWGRTeppiche || 0,
    numberofassociatedFurnplan: data?.numberofassociatedFurnplan || 0,
    zRNummer: data?.zRNummer || '',
    googlePlusPage: data?.googlePlusPage || '',
    rolleUnternehmenUmsatzvergleich: data?.rolleUnternehmenUmsatzvergleich || '',
    bIC: data?.bIC || '',
    iLDashboardAzureServiceID: data?.iLDashboardAzureServiceID || '',
    umsatzanteilHWGRRattanuGartenmoebel: data?.umsatzanteilHWGRRattanuGartenmoebel || 0,
    daystoClose: data?.daystoClose || 0,
    description: data?.description || '',
    lieferOrt: data?.lieferOrt || '',
    annualRevenue: data?.annualRevenue || 0,
    lieferstrasse2: data?.lieferstrasse2 || '',
    latestSourceTimestamp: data?.latestSourceTimestamp || '',
    parentCompany: data?.parentCompany || '',
    industry: data?.industry || '',
    objectcreateDateTime: data?.objectcreateDateTime || '',
    streetAddress2: data?.streetAddress2 || '',
    postfachPLZ: data?.postfachPLZ || '',
    isPublic: data?.isPublic || false,
    suchname1: data?.suchname1 || '',
    umsatzanteilDiverseZRLieferanten: data?.umsatzanteilDiverseZRLieferanten || 0,
    lieferLand: data?.lieferLand || '',
    tEMP_Unternehmensbefragung: data?.tEMP_Unternehmensbefragung || '',
    numberofopendeals: data?.numberofopendeals || 0,
    umsatzanteilHWGRPolstermöbel: data?.umsatzanteilHWGRPolstermöbel || 0,
    associatedContact: data?.associatedContact || '',
    associatedDeal: data?.associatedDeal || '',
    associatedTicket: data?.associatedTicket || '',
    associatedPayment: data?.associatedPayment || '',
    associatedSubscription: data?.associatedSubscription || '',
    additionalDomains: data?.additionalDomains || '',
    associatedContactIDs: data?.associatedContactIDs || '',
    associatedDealIDs: data?.associatedDealIDs || '',
    associatedTicketIDs: data?.associatedTicketIDs || '',
    associatedPaymentIDs: data?.associatedPaymentIDs || '',
    associatedSubscriptionIDs: data?.associatedSubscriptionIDs || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
