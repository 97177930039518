import { GetterTree } from 'vuex';
import { TblUpdateDocumentState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TblUpdateDocumentState, RootState> = {
  ['getTblUpdateDocuments']: (state) => state.tblUpdateDocuments,
  ['getTblUpdateDocumentsIsLoading']: (state) => state.tblUpdateDocuments?.isLoading,
  ['getTblUpdateDocumentrsTotal']: (state) => state.tblUpdateDocuments?.total,
  ['getTblUpdateDocumentsSearchParams']: (state) => state.tblUpdateDocuments?.searchParams,
  ['getDashboardDate']: (state) => state.dashboardDate,
  
  ['getTblUpdateDocumentsWerbung']: (state) => state.tblUpdateDocumentsWerbung,
  ['getTblUpdateDocumentsWerbungIsLoading']: (state) => state.tblUpdateDocumentsWerbung?.isLoading,
  ['getTblUpdateDocumentrsWerbungTotal']: (state) => state.tblUpdateDocumentsWerbung?.total,
  ['getTblUpdateDocumentsWerbungSearchParams']: (state) => state.tblUpdateDocumentsWerbung?.searchParams,
  ['getDashboadrdDocumentsStatus']: (state) => state.dashboardDocumentsStatus,
};
