
import { ActionTree } from 'vuex';
import { TblUpdateSupplierState } from './types';
import { RootState } from '../../types';
import { defaultBackendTblUpdateSupplier } from '@/shared/backend/tblUpdateSupplier';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tblUpdateSupplier, { Value, TblUpdateSupplier } from '@/shared/model/tblUpdateSupplier';
import { CompanyWrGruppenResult } from '@/shared/model/company';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.tblUpdateSuppliers');
export const actions: ActionTree<TblUpdateSupplierState, RootState> = {
  getTblUpdateSuppliers({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; supplierSearchData?: any } ) {
    commit('setTblUpdateSuppliersIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getTblUpdateSuppliersSearchParams;
    let supplierSearchData = payload?.supplierSearchData || {};
    return defaultBackendTblUpdateSupplier
      .getTblUpdateSuppliers(searchParams, supplierSearchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblUpdateSuppliers', response.data);
        commit('setTblUpdateSuppliersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTblUpdateSuppliersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTblUpdateSupplier({ commit, dispatch }, id: string) {
    return defaultBackendTblUpdateSupplier
      .getTblUpdateSupplier(id)
      .then((response: AxiosResponse<TblUpdateSupplier>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateTblUpdateSupplier({ commit, dispatch }, file: TblUpdateSupplier) {
    return defaultBackendTblUpdateSupplier
      .updateTblUpdateSupplier(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TblUpdateSupplier;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.tblUpdateSupplier_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAddSupplierCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateSupplier
      .getAddSupplierCompleted(payload)
      .then(
        (
          response: AxiosResponse<{
            result: TblUpdateSupplier;
          }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCanselSupplierCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateSupplier
      .getCanselSupplierCompleted(payload)
      .then(
        (
          response: AxiosResponse<{
            result: TblUpdateSupplier;
          }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteTblUpdateSupplier({ commit, dispatch }, id: string) {
    return defaultBackendTblUpdateSupplier
      .deleteTblUpdateSupplier(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TblUpdateSupplier;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCompanyWrGruppen({ commit, dispatch, rootGetters },payload?: { searchData?: any }) {
    console.log(
      '--ACTION---------------------------------------------------get CompanyWrGruppen-------------------------------------  ===>>> contactType  '
    );
    return defaultBackendTblUpdateSupplier
      .getCompanyWrGruppen(payload?.searchData)
      .then((response: AxiosResponse<CompanyWrGruppenResult[]>) => {
        commit('setCompanyWrGruppenIsLoading', false);
        commit('setCompanyWrGruppen', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCompanyWrGruppenIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateDashboardDate({ commit, dispatch }, date: string) {
    commit('setDashboardDate', date);
  },
  updateDashboarCompanyWrGruppen({ commit, dispatch }, date: any) {
    commit('setDashboarCompanyWrGruppen', date);
  },
  updateDashboardSuppliersStatus({ commit, dispatch }, date: any) {
    commit('setDashboardSuppliersStatus', date);
  },
};
