
import { MutationTree } from 'vuex';
import { CompanyState } from './types';
import Vue from 'vue';
import company, { Company, Value } from '@/shared/model/company';

export const mutations: MutationTree<CompanyState> = {
  setCompanys(state, payload: Value) {
    state.companys.items = payload.value.map((x) => company.parse(x));
    state.companys.total = payload['@odata.count'] || 0;
    state.companys.isLoading = false;
  },
  setCompanysTotal(state, payload: number) {
    state.companys.total = payload;
  },
  setCompanysIsLoading(state, payload: boolean) {
    state.companys.isLoading = payload;
  },
  
  setCompanyUserZrs(state, payload: any) {
    state.companyUserZrs = payload;
  },
};
