import { Component, Prop, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { Contact } from '@/shared/model/contact';
import { namespace } from 'vuex-class';
import contactPanel, { ContactPanel } from '@/shared/model/contactPanel';

const logger = new Logger('documents-conditions-dialog');
const contactPanelModule = namespace('contactPanel');
const authModule = namespace('auth');
const conditionCategoryModule = namespace('conditionCategory');
const documentTypeModule = namespace('documentType');

@Component({
  name: 'documents-conditions-dialog',
  components: {},
})
export default class DocumentsConditionsDialog extends Vue {
  @conditionCategoryModule.Action('getConditionCategorys')
  private actionGetConditionCategorys!: any;
  @conditionCategoryModule.Getter('getConditionCategorys')
  private conditionCategorys!: any;

  @documentTypeModule.Action('getDocumentTypes')
  private actionGetDocumentTypes!: any;
  @documentTypeModule.Getter('getDocumentTypes')
  private documentTypes!: any;

  @contactPanelModule.Action('updateContactPanel')
  private actionUpdateContactPanel!: any;
  @contactPanelModule.Action('getContactPanel')
  private actionGetContactPanel!: any;

  @authModule.Action('loadAzureUserId')
  private actionLoadAzureUserId!: any;
  @authModule.Action('deactivateUserOnAzure')
  private actionDeactivateUserOnAzure!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop()
  private contactModel!: Contact;

  search = '';
  singleSelect = false;
  selected: any = [];

  isSaving = false;
  showOnlySelected = false;
  contactPanel: ContactPanel = contactPanel.parse({});
  conditions: any = [];
  conditionsBackup: any = [];

  switchSelectedOnly(event: any) {
    console.log('hello :>> ', event);
    if (event) {
      this.conditionsBackup = this.conditions;
      this.conditions = this.selected;
    } else {
      this.conditions = this.conditionsBackup;
    }
  }

  async created() {
    await this.actionGetConditionCategorys()
      .then((result: any) => {
        console.log('result :>> ', result);
        // this.conditions =
        result.value.forEach((el: any) => {
          this.conditions.push({
            id: el.id,
            name: el.name,
            type: 'Konditionskategorie',
          });
        });
      })
      .catch((err: any) => {
        logger.error(err);
      });

    await this.actionGetDocumentTypes()
      .then(async (result: any) => {
        console.log('result :>> ', result);
        // this.conditions =
        result.value.forEach((el: any) => {
          this.conditions.push({
            id: el.id,
            name: el.name,
            type: 'Dokumentart',
          });
        });

        await this.actionGetContactPanel(this.contactModel.email)
          .then((result: any) => {
            console.log('result :>> ', result);
            this.setSelected(result);
          })
          .catch((err: any) => {
            logger.error(err);
          });
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  setSelected(result: any) {
    if (!result || !result.id) {
      return;
    }

    this.contactPanel = result;

    console.log('this.conditions :>> ', this.conditions);
    console.log('result :>> ', result);
    const conditions = result.conditionCategoriesAll.split(',');
    console.log('conditions :>> ', conditions);
    conditions.forEach((el: any) => {
      const condi: any = this.conditions.find((x: any) => x.name == el);
      if (condi) {
        this.selected.push(condi);
      }
    });

    const docs = result.documentTypesAll.split(',');
    console.log('docs :>> ', docs);

    docs.forEach((el: any) => {
      const doc: any = this.conditions.find((x: any) => x.name == el);
      if (doc) {
        this.selected.push(doc);
      }
    });

    //  const condi: any = this.conditions.find((x: any) => (x.name == conditions[0]));
  }

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('type'), value: 'type' },
    ];
    return headers;
  }

  async save(): Promise<void> {
    logger.log('save-user-conditions');

    // this.isSaving = true;
    console.log('this.selected :>> ', this.selected);
    console.log('this.contactModel :>> ', this.contactModel);
    const conditions: any = this.selected.filter((x: any) => x.type == 'Konditionskategorie').map((x: any) => x.name);
    const conditionsIds: any = this.selected.filter((x: any) => x.type == 'Konditionskategorie').map((x: any) => x.id);
    console.log('conditions :>> ', conditions);
    const arts: any = this.selected.filter((x: any) => x.type == 'Dokumentart').map((x: any) => x.name);
    const artsIds: any = this.selected.filter((x: any) => x.type == 'Dokumentart').map((x: any) => x.id);
    console.log('Dokumentart :>> ', arts);
    console.log('this.contactPanel.id :>> ', this.contactPanel.id);

    const payload: ContactPanel = {
      email: this.contactModel.email,
      conditionCategoriesIds: conditionsIds,
      documentTypesIds: artsIds,
      id: this.contactPanel.id,
      isZrBiActive: this.contactModel.isZrBiActive, // (AD-67) change condition since now `contactPanel` is NOT deleted //this.contactPanel.id ? this.contactPanel.isZrBiActive : true,
      conditionCategoriesAll: conditions.join(','),
      documentTypesAll: arts.join(','),
    };
    console.log('payload :>> ', payload);

    await this.actionUpdateContactPanel(payload)
      .then((result: any) => {
        this.contactPanel = result.result;
        console.log('result :>> ', result);
        this.$emit('update:contactPanelId', result.id);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  clickClose(): void {
    logger.log(`this.contactPanel.id :>> ${this.contactPanel.id} -- this.selected :>> ${this.selected.length}`);
    if (!this.contactPanel.id || this.selected.length === 0) {
      this.contactModel.isZrBiActive = false;
    }
    this.$emit('click:close');
  }

  private resetSearch() {}
}
