export interface TblUpdateSupplier {
    id: number; // uint  
    sp_zrnummer: string; // string  
    sp_name: string; // string  
    sp_changedate: string; // System.DateTime  
    sp_changefields: string; // string  
    sp_changevalue: string; // string  
    sp_isdeleted: boolean; // bool  
    supplierDoneTimeCompleted: string; // string 
    supplierUserNameCompleted: string; // string    
}

export interface CompletedInfo {
    сompletedDoneTime: string; // string 
    сompletedUserName: string; // string  ;
}
export interface Value {
    value: TblUpdateSupplier[];
    '@odata.context'?: string | undefined;
    '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<TblUpdateSupplier>): any {
    return {
        id: data?.id || 0,
        sp_zrnummer: data?.sp_zrnummer || '',
        sp_name: data?.sp_name || '',
        sp_changedate: data?.sp_changedate || '',
        sp_changefields: data?.sp_changefields || '',
        sp_changevalue: data?.sp_changevalue || '',
        sp_isdeleted: data?.sp_isdeleted || false,
        supplierDoneTimeCompleted: data?.supplierDoneTimeCompleted || '',
        supplierUserNameCompleted: data?.supplierUserNameCompleted || '',
    };
}

function parse(data?: Partial<TblUpdateSupplier>): TblUpdateSupplier {
    return {
        id: data?.id || 0,
        sp_zrnummer: data?.sp_zrnummer || '',
        sp_name: data?.sp_name || '',
        sp_changedate: data?.sp_changedate || '',
        sp_changefields: data?.sp_changefields || '',
        sp_changevalue: data?.sp_changevalue || '',
        sp_isdeleted: data?.sp_isdeleted || false,
        supplierDoneTimeCompleted: data?.supplierDoneTimeCompleted || '',
        supplierUserNameCompleted: data?.supplierUserNameCompleted || '',
    };
}

export default {
    parse,
    toAPI: toAPI,
};
