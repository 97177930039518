import { render, staticRenderFns } from "./module-role-table.html?vue&type=template&id=6843bba9&scoped=true&"
import script from "./module-role-table.ts?vue&type=script&lang=ts&"
export * from "./module-role-table.ts?vue&type=script&lang=ts&"
import style0 from "./module-role-table.scss?vue&type=style&index=0&id=6843bba9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6843bba9",
  null
  
)

/* custom blocks */
import block0 from "./module-role-table.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fcontact-list%2Fcontact%2Fcontact-panel%2Fmodule-role-table%2Fmodule-role-table.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VIcon,VListItemAction,VListItemContent,VListItemTitle,VTooltip})
