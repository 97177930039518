
import { MutationTree } from 'vuex';
import { GspUserRoleDetailState } from './types';
import Vue from 'vue';
import gspUserRoleDetail, { GspUserRoleDetail, Value } from '@/shared/model/gspUserRoleDetail';

export const mutations: MutationTree<GspUserRoleDetailState> = {
  setGspUserRoleDetails(state, payload: Value) {
    state.gspUserRoleDetails.items = payload.value.map((x) => gspUserRoleDetail.parse(x));
    state.gspUserRoleDetails.total = payload['@odata.count'] || 0;
    state.gspUserRoleDetails.isLoading = false;
  },
  setGspUserRoleDetailsTotal(state, payload: number) {
    state.gspUserRoleDetails.total = payload;
  },
  setGspUserRoleDetailsIsLoading(state, payload: boolean) {
    state.gspUserRoleDetails.isLoading = payload;
  },
};
