
import { GetterTree } from 'vuex';
import { CompanyState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CompanyState, RootState> = {
  ['getCompanys']: (state) => state.companys,
  ['getCompanysIsLoading']: (state) => state.companys?.isLoading,
  ['getCompanysTotal']: (state) => state.companys?.total,
  ['getCompanysSearchParams']: (state) => state.companys?.searchParams,

  ['getCompanyUserZrs']: (state) => state.companyUserZrs,
};
