import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import ODataFilterBuilder from 'odata-filter-builder';
import { Value } from '../model/userActionsUserRoleChanges';

const logger = new Logger('backend.UserActionsUserRoleChanges');
export interface BackendUserActionsUserRoleChanges {
  getUserActionsUserRoleChanges: (searchParams: SearchParams, contactId: string) => AxiosPromise<Value>;
}

export const defaultBackendUserActionsUserRoleChanges: BackendUserActionsUserRoleChanges = {
  getUserActionsUserRoleChanges(searchParams: SearchParams, contactId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq('contact_id', contactId, false);

    const url = DefaultBackendHelper.makeUrl(
      `${URLS.userActionsUserRoleChangesOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.info('getUserActionsUserRoleChanges: ' + url);
    return instance.get<Value>(url);
  },
};
