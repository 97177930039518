import { DataOptions, getDefault } from './DataOptions';

export interface SearchParams {
  dataOption: DataOptions;
  orClauseFieldsIds: string[];
  filter: string;
  view?: string;
}

export const SearchParamsEmpty: SearchParams = {
  dataOption: { ...getDefault },
  orClauseFieldsIds: [],
  filter: '',
};
