import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import company, { Company, Value } from '../model/company';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Company');
export interface BackendCompany {
  getCompany(id: string): AxiosPromise<Company>;
  getCompanys: (searchParams: SearchParams) => AxiosPromise<Value>;
  getCompaniesIframe: () => AxiosPromise<Value>;
  getUserCompanyZr: (data: any) => AxiosPromise<Value>;
  deleteCompany(id: string): AxiosPromise;
  updateCompany(Company: Company): AxiosPromise<any>;
}

export const defaultBackendCompany: BackendCompany = {
  getCompany(id: string): AxiosPromise<Company> {
    let url = `${URLS.companyOdata}/${id}`;
    return instance.get<Company>(url);
  },
  getCompanys(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.companyOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getCompanys${url}`);
    return instance.get<Value>(url);
  },

  getUserCompanyZr(data: any): AxiosPromise<Value> {
    logger.debug('getUserCompanyZr');
    return instance.put(`${URLS.company}/getUserCompanyZr`, data);
  },

  getCompaniesIframe(): AxiosPromise<Value> {
    logger.debug('GetCompaniesIframe');
    return instance.get(`${URLS.company}/GetCompaniesIframe`);
  },
  deleteCompany(id: string): AxiosPromise {
    logger.debug('deleteCompany');
    return instance.delete(`${URLS.company}/${id}`);
  },
  updateCompany(Company: Company): AxiosPromise<any> {
    logger.debug('updateCompany');
    return instance.put<Company>(`${URLS.company}/update`, company.toAPI(Company));
  },
};
