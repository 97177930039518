export interface TblUpdateCondition {
  id: number; // uint
  cnd_zrnummer: string; // string
  cnd_name: string; // string
  cnd_changedate: string; // System.DateTime
  cnd_changefields: string; // string
  cnd_changevalue: string; // string
  cnd_isdeleted: boolean; // bool
  conditionDoneTimeCompleted: string; // string
  conditionUserNameCompleted: string; // string
}

export interface Value {
  value: TblUpdateCondition[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<TblUpdateCondition>): any {
  return {
    id: data?.id || 0,
    cnd_zrnummer: data?.cnd_zrnummer || '',
    cnd_name: data?.cnd_name || '',
    cnd_changedate: data?.cnd_changedate || '',
    cnd_changefields: data?.cnd_changefields || '',
    cnd_changevalue: data?.cnd_changevalue || '',
    cnd_isdeleted: data?.cnd_isdeleted || false,
    conditionDoneTimeCompleted: data?.conditionDoneTimeCompleted || '',
    conditionUserNameCompleted: data?.conditionUserNameCompleted || '',
  };
}

function parse(data?: Partial<TblUpdateCondition>): TblUpdateCondition {
  return {
    id: data?.id || 0,
    cnd_zrnummer: data?.cnd_zrnummer || '',
    cnd_name: data?.cnd_name || '',
    cnd_changedate: data?.cnd_changedate || '',
    cnd_changefields: data?.cnd_changefields || '',
    cnd_changevalue: data?.cnd_changevalue || '',
    cnd_isdeleted: data?.cnd_isdeleted || false,
    conditionDoneTimeCompleted: data?.conditionDoneTimeCompleted || '',
    conditionUserNameCompleted: data?.conditionUserNameCompleted || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
