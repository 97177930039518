import { render, staticRenderFns } from "./edit-multiple-module-roles.html?vue&type=template&id=5ccf0aa8&scoped=true&"
import script from "./edit-multiple-module-roles.ts?vue&type=script&lang=ts&"
export * from "./edit-multiple-module-roles.ts?vue&type=script&lang=ts&"
import style0 from "./edit-multiple-module-roles.scss?vue&type=style&index=0&id=5ccf0aa8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ccf0aa8",
  null
  
)

/* custom blocks */
import block0 from "./edit-multiple-module-roles.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fcontact-list%2Fcontact%2Fcontact-panel%2Fmodule-role-table%2Fedit-multiple-module-roles%2Fedit-multiple-module-roles.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VSelect})
