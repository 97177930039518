import { ActionTree } from 'vuex';
import { CompanyState } from './types';
import { RootState } from '../../types';
import { defaultBackendCompany } from '@/shared/backend/company';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import company, { Value, Company } from '@/shared/model/company';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.companys');
export const actions: ActionTree<CompanyState, RootState> = {
  getCompanys({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setCompanysIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getCompanysSearchParams;
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendCompany
      .getCompanys(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setCompanys', response.data);
        commit('setCompanysIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCompanysIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCompany({ commit, dispatch }, id: string) {
    return defaultBackendCompany
      .getCompany(id)
      .then((response: AxiosResponse<Company>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getUserCompanyZr({ commit, dispatch }, payload: any) {
    return defaultBackendCompany
      .getUserCompanyZr(payload)
      .then((response: AxiosResponse<Value>) => {
        commit('setCompanyUserZrs', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCompaniesIframe({ commit, dispatch }, id: string) {
    return defaultBackendCompany
      .getCompaniesIframe()
      .then((response: AxiosResponse<Value>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateCompany({ commit, dispatch }, file: Company) {
    return defaultBackendCompany
      .updateCompany(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Company;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.company_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteCompany({ commit, dispatch }, id: string) {
    return defaultBackendCompany
      .deleteCompany(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Company;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
