
import { GetterTree } from 'vuex';
import { TblUpdateConditionState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TblUpdateConditionState, RootState> = {
  ['getTblUpdateConditions']: (state) => state.tblUpdateConditions,
  ['getTblUpdateConditionsIsLoading']: (state) => state.tblUpdateConditions?.isLoading,
  ['getTblUpdateConditionrsTotal']: (state) => state.tblUpdateConditions?.total,
  ['getTblUpdateConditionsSearchParams']: (state) => state.tblUpdateConditions?.searchParams,

  ['getDashboardDate']: (state) => state.dashboardDate,
  ['getDashboarCompanyWrGruppen']: (state) => state.dashboarCompanyWrGruppen,
  ['getDashboadrdConditionsStatus']: (state) => state.dashboardConditionsStatus,
  ['getConditionsVisible']: (state) => state.conditionsVisible,
};
