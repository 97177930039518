import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import gspAttribute, { GspAttribute, Value } from '../model/gspAttribute';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.GspAttribute');
export interface BackendGspAttribute {
  getGspAttribute(id: string): AxiosPromise<GspAttribute>;
  getGspAttributes: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteGspAttribute(id: string): AxiosPromise;
  updateGspAttribute(GspAttribute: GspAttribute): AxiosPromise<any>;
}

export const defaultBackendGspAttribute: BackendGspAttribute = {
  getGspAttribute(id: string): AxiosPromise<GspAttribute> {
    let url = `${URLS.gspAttribute}/${id}`;
    return instance.get<GspAttribute>(url);
  },
  getGspAttributes(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.gspAttributeOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getGspAttributes${url}`);
    return instance.get<Value>(url);
  },

  deleteGspAttribute(id: string): AxiosPromise {
    logger.debug('deleteGspAttribute');
    return instance.delete(`${URLS.gspAttribute}/${id}`);
  },
  updateGspAttribute(GspAttribute: GspAttribute): AxiosPromise<any> {
    logger.debug('updateGspAttribute');
    return instance.put<GspAttribute>(`${URLS.gspAttribute}/update`, gspAttribute.toAPI(GspAttribute));
  },
};
