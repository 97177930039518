
import { GetterTree } from 'vuex';
import { GspAttributeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<GspAttributeState, RootState> = {
  ['getGspAttributes']: (state) => state.gspAttributes,
  ['getGspAttributesIsLoading']: (state) => state.gspAttributes?.isLoading,
  ['getGspAttributesTotal']: (state) => state.gspAttributes?.total,
  ['getGspAttributesSearchParams']: (state) => state.gspAttributes?.searchParams,
};
