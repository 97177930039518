export interface ContactPanel {
  id: string; // System.Guid
  email: string; // string
  //conditionCategories: System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.ConditionCategoryDm>; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.ConditionCategoryDm>
  conditionCategoriesIds: any;
  documentTypesIds: any;
  conditionCategoriesAll: string; // string
  documentTypesAll: string; // string
  isZrBiActive: boolean;
  // documentTypes: System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.DocumentTypeDm>; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.DocumentTypeDm>
  isIlKpiActive?: boolean;
  isKpiChangeMode?: boolean; // if click on `IL-KPI` checkbox this is TRUE otherwise FALSE
  katalogwand?: boolean; // if click on `katalogwand` send checkbox value otherwise NULL
}

export interface Value {
  value: ContactPanel[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ContactPanel>): any {
  return {
    Id: data?.id || undefined,
    Email: data?.email || '',
    // ConditionCategories: data?.conditionCategories || '',
    ConditionCategoriesIds: data?.conditionCategoriesIds || null,

    ConditionCategoriesAll: data?.conditionCategoriesAll || '',
    DocumentTypesAll: data?.documentTypesAll || '',
    // DocumentTypes: data?.documentTypes || '',
    DocumentTypesIds: data?.documentTypesIds || null,
    IsZrBiActive: data?.isZrBiActive || false,

    IsIlKpiActive: data?.isIlKpiActive || false,
    IsKpiChangeMode: data?.isKpiChangeMode || false,
    Katalogwand: data?.katalogwand === true || data?.katalogwand === false ? data?.katalogwand : null,
  };
}

function parse(data?: Partial<ContactPanel>): ContactPanel {
  return {
    id: data?.id || '',
    email: data?.email || '',
    // conditionCategories: data?.conditionCategories || '',
    conditionCategoriesIds: data?.conditionCategoriesIds || '',
    conditionCategoriesAll: data?.conditionCategoriesAll || '',
    documentTypesAll: data?.documentTypesAll || '',
    // documentTypes: data?.documentTypes || '',
    documentTypesIds: data?.documentTypesIds || '',
    isZrBiActive: data?.isZrBiActive || false,

    isIlKpiActive: data?.isIlKpiActive || false,
    isKpiChangeMode: data?.isKpiChangeMode || false,
    katalogwand: data?.katalogwand || false,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
