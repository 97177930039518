
import { MutationTree } from 'vuex';
import { DocumentTypeState } from './types';
import Vue from 'vue';
import documentType, { DocumentType, Value } from '@/shared/model/documentType';

export const mutations: MutationTree<DocumentTypeState> = {
  setDocumentTypes(state, payload: Value) {
    state.documentTypes.items = payload.value.map((x) => documentType.parse(x));
    state.documentTypes.total = payload['@odata.count'] || 0;
    state.documentTypes.isLoading = false;
  },
  setDocumentTypesTotal(state, payload: number) {
    state.documentTypes.total = payload;
  },
  setDocumentTypesIsLoading(state, payload: boolean) {
    state.documentTypes.isLoading = payload;
  },
};
