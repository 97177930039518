
import { GetterTree } from 'vuex';
import { GspUserRoleDetailState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<GspUserRoleDetailState, RootState> = {
  ['getGspUserRoleDetails']: (state) => state.gspUserRoleDetails,
  ['getGspUserRoleDetailsIsLoading']: (state) => state.gspUserRoleDetails?.isLoading,
  ['getGspUserRoleDetailsTotal']: (state) => state.gspUserRoleDetails?.total,
  ['getGspUserRoleDetailsSearchParams']: (state) => state.gspUserRoleDetails?.searchParams,
};
