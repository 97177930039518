import { render, staticRenderFns } from "./change-log-info.html?vue&type=template&id=718eef66&scoped=true&"
import script from "./change-log-info.ts?vue&type=script&lang=ts&"
export * from "./change-log-info.ts?vue&type=script&lang=ts&"
import style0 from "./change-log-info.scss?vue&type=style&index=0&id=718eef66&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718eef66",
  null
  
)

/* custom blocks */
import block0 from "./change-log-info.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fcontact-list%2Fcontact%2Fchange-log-info%2Fchange-log-info.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDivider,VIcon,VTooltip})
