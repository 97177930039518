
import { GetterTree } from 'vuex';
import { ContactPanelState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ContactPanelState, RootState> = {
  ['getContactPanels']: (state) => state.contactPanels,
  ['getContactPanelsIsLoading']: (state) => state.contactPanels?.isLoading,
  ['getContactPanelsTotal']: (state) => state.contactPanels?.total,
  ['getContactPanelsSearchParams']: (state) => state.contactPanels?.searchParams,
};
