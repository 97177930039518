
export interface GspAttribute {
    id: number; // int
    name: string; // string
    // modules: any; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspModuleDm>;
    created: string; // System.DateTime?
    creatorId: string; // string
    creatorEmail: string; // string

}

export interface Value {
  value: GspAttribute[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GspAttribute>): any {
  return {
    Id: data?.id || 0,
    Name: data?.name || '',
    // Modules: data?.modules || '',
    Created: data?.created || undefined,
    CreatorId: data?.creatorId || undefined,
    CreatorEmail: data?.creatorEmail || undefined,
  };
}

function parse(data?: Partial<GspAttribute>): GspAttribute {
  return {
    id: data?.id || 0,
    name: data?.name || '',
    // modules: data?.modules || '',
    created: data?.created || '',
    creatorId: data?.creatorId || '',
    creatorEmail: data?.creatorEmail || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
