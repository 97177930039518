import { Component, Vue } from 'vue-property-decorator';
import SearchForm from '@/views/home/search-form/search-form.vue'; // @ is an alias to /src
import MySelectionTable from '@/views/home/my-selection-table/my-selection-table.vue'; // @ is an alias to /src
import ContactList from '@/views/home/contact-list/contact-list.vue'; // @ is an alias to /src

@Component({
  components: {
    ContactList,
  },
})
export default class Home extends Vue {
  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }
}
