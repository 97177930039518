import { ActionTree } from 'vuex';
import { UserActionsUserRoleChangesState } from './types';
import { RootState } from '../../types';
import { defaultBackendUserActionsUserRoleChanges } from '@/shared/backend/UserActionsUserRoleChanges';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import { Value } from '@/shared/model/userActionsUserRoleChanges';
import { AxiosResponse } from 'axios';

const logger = new Logger('actions.userActionsUserRoleChanges');

export const actions: ActionTree<UserActionsUserRoleChangesState, RootState> = {
  getUserActionsUserRoleChanges({ commit, getters }, roleId: string) {
    commit('setUserActionsUserRoleChangesIsLoading', true);
    let searchParams = getters.getUserActionsUserRoleChangesSearchParams;

    return defaultBackendUserActionsUserRoleChanges
      .getUserActionsUserRoleChanges(searchParams, roleId)
      .then((response: AxiosResponse<Value>) => {
        commit('setUserActionsUserRoleChanges', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setUserActionsUserRoleChangesIsLoading', false);
      });
  },
};
