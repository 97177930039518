import { render, staticRenderFns } from "./contact-list.html?vue&type=template&id=0b5c3ec2&scoped=true&"
import script from "./contact-list.ts?vue&type=script&lang=ts&"
export * from "./contact-list.ts?vue&type=script&lang=ts&"
import style0 from "./contact-list.scss?vue&type=style&index=0&id=0b5c3ec2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b5c3ec2",
  null
  
)

/* custom blocks */
import block0 from "./contact-list.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fcontact-list%2Fcontact-list.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardText,VCardTitle,VDataFooter,VDataIterator,VIcon,VImg,VProgressCircular})
