
import { MutationTree } from 'vuex';
import { GspModuleState } from './types';
import Vue from 'vue';
import gspModule, { GspModule, Value } from '@/shared/model/gspModule';

export const mutations: MutationTree<GspModuleState> = {
  setGspModules(state, payload: Value) {
    state.gspModules.items = payload.value.map((x) => gspModule.parse(x));
    state.gspModules.total = payload['@odata.count'] || 0;
    state.gspModules.isLoading = false;
  },
  setGspModulesTotal(state, payload: number) {
    state.gspModules.total = payload;
  },
  setGspModulesIsLoading(state, payload: boolean) {
    state.gspModules.isLoading = payload;
  },
};
