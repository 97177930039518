
import { MutationTree } from 'vuex';
import { ConditionCategoryState } from './types';
import Vue from 'vue';
import conditionCategory, { ConditionCategory, Value } from '@/shared/model/conditionCategory';

export const mutations: MutationTree<ConditionCategoryState> = {
  setConditionCategorys(state, payload: Value) {
    state.conditionCategorys.items = payload.value.map((x) => conditionCategory.parse(x));
    state.conditionCategorys.total = payload['@odata.count'] || 0;
    state.conditionCategorys.isLoading = false;
  },
  setConditionCategorysTotal(state, payload: number) {
    state.conditionCategorys.total = payload;
  },
  setConditionCategorysIsLoading(state, payload: boolean) {
    state.conditionCategorys.isLoading = payload;
  },
};
