import { UserActionsUserRoleChangesState } from './types';

export const state = (): UserActionsUserRoleChangesState => initialState();

export const initialState = (): UserActionsUserRoleChangesState => ({
  userActionsUserRoleChanges: {
    items: [],
    isLoading: undefined,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['erstellt_am'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['erstellt_von_email', 'role_name', 'service_name'],
    },
  },
});
