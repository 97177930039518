
import { MutationTree } from 'vuex';
import { GspRoleDataState } from './types';
import Vue from 'vue';
import gspRoleData, { GspRoleData, Value } from '@/shared/model/gspRoleData';

export const mutations: MutationTree<GspRoleDataState> = {
  setGspRolesData(state, payload: Value) {
    state.gspRolesData.items = payload.value.map((x) => gspRoleData.parse(x));
    state.gspRolesData.total = payload['@odata.count'] || 0;
    state.gspRolesData.isLoading = false;
  },
  resetGspRoleData(state) {
    state.gspRolesData.items = [];
    state.gspRolesData.total = 0;
    state.gspRolesData.isLoading = false;
  },
  setGspRolesDataTotal(state, payload: number) {
    state.gspRolesData.total = payload;
  },
  setGspRolesDataIsLoading(state, payload: boolean) {
    state.gspRolesData.isLoading = payload;
  },
};
