
import { GetterTree } from 'vuex';
import { DocumentTypeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DocumentTypeState, RootState> = {
  ['getDocumentTypes']: (state) => state.documentTypes,
  ['getDocumentTypesIsLoading']: (state) => state.documentTypes?.isLoading,
  ['getDocumentTypesTotal']: (state) => state.documentTypes?.total,
  ['getDocumentTypesSearchParams']: (state) => state.documentTypes?.searchParams,
};
