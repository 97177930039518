import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import gspRoleData, { GspRoleData, Value } from '../model/gspRoleData';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.GspRoleData');
export interface BackendGspRoleData {
  getGspRoleData(id: string): AxiosPromise<GspRoleData>;
  getGspRolesData: (searchParams: SearchParams,roleId?: number) => AxiosPromise<Value>;
  deleteGspRoleData(id: string): AxiosPromise;
  updateGspRoleData(GspRoleData: GspRoleData): AxiosPromise<any>;
}

export const defaultBackendGspRoleData: BackendGspRoleData = {
  getGspRoleData(id: string): AxiosPromise<GspRoleData> {
    let url = `${URLS.gspRoleDataOdata}/${id}`;
    return instance.get<GspRoleData>(url);
  },
  getGspRolesData(searchParams: SearchParams, roleId?: number): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (roleId) {
      odfb.eq('roleId', roleId, false);
    }
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.gspRoleDataOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=Service($select=name),Module($select=name),Attributes($select=id,name)']
    );
    logger.log(`getGspRolesData${url}`);
    return instance.get<Value>(url);
  },

  deleteGspRoleData(id: string): AxiosPromise {
    logger.debug('deleteGspRoleData');
    return instance.delete(`${URLS.gspRoleData}/${id}`);
  },
  updateGspRoleData(GspRoleData: GspRoleData): AxiosPromise<any> {
    logger.debug('updateGspRoleData');
    return instance.put<GspRoleData>(`${URLS.gspRoleData}/update`, gspRoleData.toAPI(GspRoleData));
  },
};
