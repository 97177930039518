
import { ActionTree } from 'vuex';
import { GspAttributeState } from './types';
import { RootState } from '../../types';
import { defaultBackendGspAttribute } from '@/shared/backend/gspAttribute';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import gspAttribute, { Value, GspAttribute } from '@/shared/model/gspAttribute';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.gspAttributes');
export const actions: ActionTree<GspAttributeState, RootState> = {
  getGspAttributes({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setGspAttributesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getGspAttributesSearchParams;
    return defaultBackendGspAttribute
      .getGspAttributes(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setGspAttributes', response.data);
        commit('setGspAttributesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setGspAttributesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getGspAttribute({ commit, dispatch }, id: string) {
    return defaultBackendGspAttribute
      .getGspAttribute(id)
      .then((response: AxiosResponse<GspAttribute>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateGspAttribute({ commit, dispatch }, file: GspAttribute) {
    return defaultBackendGspAttribute
      .updateGspAttribute(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspAttribute;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.gspAttribute_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteGspAttribute({ commit, dispatch }, id: string) {
    return defaultBackendGspAttribute
      .deleteGspAttribute(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspAttribute;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
