
import { GetterTree } from 'vuex';
import { TblUpdateSupplierState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TblUpdateSupplierState, RootState> = {
  ['getTblUpdateSuppliers']: (state) => state.tblUpdateSuppliers,
  ['getTblUpdateSuppliersIsLoading']: (state) => state.tblUpdateSuppliers?.isLoading,
  ['getTblUpdateSuppliersTotal']: (state) => state.tblUpdateSuppliers?.total,
  ['getTblUpdateSuppliersSearchParams']: (state) => state.tblUpdateSuppliers?.searchParams,

  ['getDashboardSupplieDate']: (state) => state.dashboardDate,
  ['getDashboarCompanyWrGruppen']: (state) => state.dashboarCompanyWrGruppen,
  ['getDashboadrSupplierStatus']: (state) => state.dashboardSuppliersStatus,
};
