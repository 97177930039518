import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class DialogBoxComponent extends Vue {
  dialog = false;

  messageBox = false;

  resolve: ((res: boolean) => void) | null = null;
  reject: ((res: boolean) => void) | null = null;

  message: string = '';
  title: string = '';
  options = {
    okText: 'OK',
    cancelText: 'Abbrechen',
    hasCancelBtn: true,
    color: 'white',
    width: 750,
    zIndex: 200,
  };

  openConfirm(title: string, message: string, options?: object) {
    this.configureOptions(title, message, options);
    return new Promise((resolve: any, reject: any) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  openMessageBox(title: string, message: string, options?: object) {
    this.messageBox = true;
    this.configureOptions(title, message, options);
    this.resolve = null;
    this.reject = null;
  }

  private configureOptions(title: string, message: string, options?: object) {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(this.options, options);
  }

  private agree() {
    if (!this.messageBox && this.resolve != null) {
      this.resolve(true);
    }
    this.dialog = false;
    this.messageBox = false;
  }

  private cancel() {
    if (this.resolve != null) {
      this.resolve(false);
    }
    this.dialog = false;
    this.messageBox = false;
  }
}
