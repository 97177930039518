export interface TblUpdateDocument {
  id: number; // uint
  doc_zrnummer: string; // string
  doc_name: string; // string
  doc_path: string; // string
  doc_type: string; // string
  doc_sector: string; // string
  doc_changedate: string; // System.DateTime
  doc_category: string; // string
  doc_erstellt_von: string; // string
  doc_path_full: string; // string
  doc_isdeleted: boolean; // bool
  documentDoneTimeCompleted: string; // string
  documentUserNameCompleted: string; // string
}

export interface Value {
  value: TblUpdateDocument[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<TblUpdateDocument>): any {
  return {
    id: data?.id || 0,
    doc_zrnummer: data?.doc_zrnummer || '',
    doc_name: data?.doc_name || '',
    doc_path: data?.doc_path || '',
    doc_type: data?.doc_type || '',
    doc_erstellt_von: data?.doc_erstellt_von || '',
    doc_path_full: data?.doc_path_full || '',
    doc_sector: data?.doc_sector || '',
    doc_changedate: data?.doc_changedate || '',
    doc_category: data?.doc_category || '',
    doc_isdeleted: data?.doc_isdeleted || false,
    documentDoneTimeCompleted: data?.documentDoneTimeCompleted || '',
    documentUserNameCompleted: data?.documentUserNameCompleted || '',
  };
}

function parse(data?: Partial<TblUpdateDocument>): TblUpdateDocument {
  return {
    id: data?.id || 0,
    doc_zrnummer: data?.doc_zrnummer || '',
    doc_name: data?.doc_name || '',
    doc_path: data?.doc_path || '',
    doc_type: data?.doc_type || '',
    doc_sector: data?.doc_sector || '',
    doc_erstellt_von: data?.doc_erstellt_von || '',
    doc_path_full: data?.doc_path_full || '',
    doc_changedate: data?.doc_changedate || '',
    doc_category: data?.doc_category || '',
    doc_isdeleted: data?.doc_isdeleted || false,
    documentDoneTimeCompleted: data?.documentDoneTimeCompleted || '',
    documentUserNameCompleted: data?.documentUserNameCompleted || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
