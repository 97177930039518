import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GspUserRoleDetailInner } from '@/shared/model/gspUserRoleDetail';
import { GspRole } from '@/shared/model/gspRole';
import { VCheckbox, VSelect } from 'vuetify/lib';

@Component({ components: { VCheckbox, VSelect } })
export default class EditMultipleModuleRolesDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => [] })
  private editedUserRoleDetails!: GspUserRoleDetailInner[];

  @Prop({ default: () => [] })
  private roles!: GspRole[];

  private editedModuleRoles: {
    name: string;
    assignedGspRoleId: number;
  }[] = [];

  private setupEditedModuleRoles() {
    this.editedModuleRoles = [];

    for (let roleDetail of this.editedUserRoleDetails) {
      for (let moduleRole of roleDetail.moduleRoles ?? []) {
        if (moduleRole.name && !this.editedModuleRoles.find((x) => x.name == moduleRole.name)) {
          this.editedModuleRoles.push({
            name: moduleRole.name,
            assignedGspRoleId: 0,
          });
        }
      }
    }
  }

  @Watch('dialog')
  private onChangeDialog() {
    if (this.dialog) {
      this.setupEditedModuleRoles();
    }
  }

  private onClickClose() {
    this.$emit('click:close');
  }

  private onClickSave() {
    const editedUserRoleDetails = [...this.editedUserRoleDetails];

    editedUserRoleDetails.forEach((userRoleDetail) => {
      userRoleDetail.moduleRoles?.forEach((moduleRole) => {
        const editedModuleRole = this.editedModuleRoles.find((x) => x.name == moduleRole.name);
        if (editedModuleRole != undefined) {
          if (editedModuleRole.assignedGspRoleId == -1) moduleRole.roles = []; // Remove all assignments
          else if (editedModuleRole.assignedGspRoleId > 0) moduleRole.roles = [editedModuleRole.assignedGspRoleId];
        }
      });
    });

    this.$emit('click:save', editedUserRoleDetails);
  }

  private get selectionItems(): { name: string; id: number }[] {
    return [
      { name: this.$tc('keep_unchanged'), id: 0 },
      { name: this.$tc('remove_assignment'), id: -1 },
      ...this.roles,
    ];
  }
}
