
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import documentType, { DocumentType, Value } from '../model/documentType';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DocumentType');
export interface BackendDocumentType {
  getDocumentType(id: string): AxiosPromise<DocumentType>;
  getDocumentTypes: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteDocumentType(id: string): AxiosPromise;
  updateDocumentType(DocumentType: DocumentType): AxiosPromise<any>;
}

export const defaultBackendDocumentType: BackendDocumentType = {
  getDocumentType(id: string): AxiosPromise<DocumentType> {
    let url = `${URLS.documentTypeOdata}/${id}`;
    return instance.get<DocumentType>(url);
  },
  getDocumentTypes(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.documentTypeOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDocumentTypes${url}`);
    return instance.get<Value>(url);
  },

  deleteDocumentType(id: string): AxiosPromise {
    logger.debug('deleteDocumentType');
    return instance.delete(`${URLS.documentType}/${id}`);
  },
  updateDocumentType(DocumentType: DocumentType): AxiosPromise<any> {
    logger.debug('updateDocumentType');
    return instance.put<DocumentType>(`${URLS.documentType}/update`, documentType.toAPI(DocumentType));
  },
};
