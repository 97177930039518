import { MutationTree } from 'vuex';
import { TblUpdateDocumentState } from './types';
import Vue from 'vue';
import tblUpdateDocument, { TblUpdateDocument, Value } from '@/shared/model/tblUpdateDocument';

export const mutations: MutationTree<TblUpdateDocumentState> = {
  setTblUpdateDocuments(state, payload: Value) {
    state.tblUpdateDocuments.items = payload.value.map((x) => tblUpdateDocument.parse(x));
    state.tblUpdateDocuments.total = payload['@odata.count'] || 0;
    state.tblUpdateDocuments.isLoading = false;
  },
  setTblUpdateDocumentsTotal(state, payload: number) {
    state.tblUpdateDocuments.total = payload;
  },
  setTblUpdateDocumentsIsLoading(state, payload: boolean) {
    state.tblUpdateDocuments.isLoading = payload;
  },
  setDashboardDate(state, payload: string) {
    state.dashboardDate = payload;
  },
  setDashboardDocumentsStatus(state, payload: string) {
    state.dashboardDocumentsStatus = payload;
  },

  setTblUpdateDocumentsWerbung(state, payload: Value) {
    state.tblUpdateDocumentsWerbung.items = payload.value.map((x) => tblUpdateDocument.parse(x));
    state.tblUpdateDocumentsWerbung.total = payload['@odata.count'] || 0;
    state.tblUpdateDocumentsWerbung.isLoading = false;
  },
  setTblUpdateDocumentsWerbungTotal(state, payload: number) {
    state.tblUpdateDocumentsWerbung.total = payload;
  },
  setTblUpdateDocumentsWerbungIsLoading(state, payload: boolean) {
    state.tblUpdateDocumentsWerbung.isLoading = payload;
  },
};
