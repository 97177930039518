import { render, staticRenderFns } from "./change-log-info-table.html?vue&type=template&id=09f85e70&scoped=true&"
import script from "./change-log-info-table.ts?vue&type=script&lang=ts&"
export * from "./change-log-info-table.ts?vue&type=script&lang=ts&"
import style0 from "./change-log-info-table.scss?vue&type=style&index=0&id=09f85e70&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09f85e70",
  null
  
)

/* custom blocks */
import block0 from "./change-log-info-table.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fcontact-list%2Fcontact%2Fchange-log-info%2Fchange-log-info-table%2Fchange-log-info-table.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog})
