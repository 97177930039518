import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex';
import { Snackbar } from '../model/snackbar';
import { RootState } from './types';
import { instance } from '../backend';
import DateUtil from '@/shared/utils/DateUtils';
import { auth } from './modules/auth';
import { company } from './modules/company';
import { conditionCategory } from './modules/conditionCategory';
import { contact } from './modules/contact';
import { contactPanel } from './modules/contactPanel';
import { documentType } from './modules/documentType';
import { gspRole } from './modules/gspRole';
import { gspUserRole } from './modules/gspUserRole';
import { gspUserRoleDetail } from './modules/gspUserRoleDetail';
import { gspAttribute } from './modules/gspAttribute';
import { gspModule } from './modules/gspModule';
import { gspService } from './modules/gspService';
import { gspRoleData } from './modules/gspRoleData';
import { tblUpdateSupplier } from './modules/tblUpdateSupplier';
import { tblUpdateCondition } from './modules/tblUpdateCondition';
import { tblUpdateDocument } from './modules/tblUpdateDocument';
import {userActionsUserRoleChanges} from './modules/userActionsUserRoleChanges';


Vue.use(Vuex);

export const actions: ActionTree<State, RootState> = {
  async hideSnackbar({ commit }) {
    commit('setSnackbarVisibility', false);
  },
  setRowsPerPage({ commit }, rows: number) {
    commit('setRowsPerPage', rows);
  },
  /**
   * Load backend API version information.
   * @param commit
   */
  ['load_info']: ({ commit }) => {
    instance.get<any>('/api/auth/info').then((value) => {
      if (value && value.data) {
        commit('set_info', value.data);
      }
    });
  },
};

export const mutations: MutationTree<State> = {
  showError: (s: State, payload: any) => {
    s.snackbarlog.push('  error:' + DateUtil.formatDateTimeISO(new Date()) + payload);
    s.snackbar.show = true;
    s.snackbar.color = 'error';
    s.snackbar.text = payload;
    s.snackbar.duration = 5000;
  },
  showSuccess: (s: State, payload: any) => {
    s.snackbarlog.push('success:' + DateUtil.formatDateTimeISO(new Date()) + payload);
    s.snackbar.show = true;
    s.snackbar.color = 'success';
    s.snackbar.text = payload;
    s.snackbar.duration = 5000;
  },
  setSnackbarError: (s: State, payload: any) => {
    s.snackbarlog.push('  error:' + DateUtil.formatDateTimeISO(new Date()) + payload);
    s.snackbar.show = true;
    s.snackbar.color = 'error';
    s.snackbar.text = payload.message;
    s.snackbar.duration = payload.duration;
  },
  setSnackbarSuccess: (s: State, payload: any) => {
    s.snackbarlog.push('success:' + DateUtil.formatDateTimeISO(new Date()) + payload);
    s.snackbar.show = true;
    s.snackbar.color = 'success';
    s.snackbar.text = payload.message;
    s.snackbar.duration = payload.duration;
  },
  setSnackbarVisibility: (s: State, visibility: boolean) => {
    s.snackbar.show = visibility;
  },
  setRowsPerPage: (s: State, rows: number) => {
    s.pagination.rowsPerPage = rows;
  },
  ['set_info']: (s: State, info: any) => {
    s.apiInfo = info;
  },
  ['set_dateFormat']: (s: State, dateFormat: any) => {
    s.dateFormat = dateFormat;
  },
};

export const getters: GetterTree<State, RootState> = {
  ['info']: (s: State) => s.apiInfo,
  ['frontend_info']: (s: State) => process.env.VERSION || 0,
  ['dateFormat']: (s: State) => s.dateFormat,
};

export interface State {
  apiInfo: string | null;
  pagination: any;
  dateFormat: string;
  snackbar: Snackbar;
  snackbarlog: string[];
}

export const state = (): State => ({
  apiInfo: null,
  snackbar: {
    show: false,
    color: '',
    text: '',
    duration: 5000,
  },
  snackbarlog: [],
  pagination: {
    rowsPerPage: 25,
  },
  dateFormat: 'DD.MM.YYYY',
});

const storeOptions: StoreOptions<RootState> = {
  state: state() as any,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    company,
    conditionCategory,
    contact,
    contactPanel,
    documentType,
    gspRole,
    gspUserRole,
    gspUserRoleDetail,
    gspAttribute,
    gspModule,
    gspService,
    gspRoleData,
    tblUpdateSupplier,
    tblUpdateCondition,
    tblUpdateDocument,
    userActionsUserRoleChanges
  },
};
const store = new Vuex.Store<RootState>(storeOptions);

export default store;
