
import { GetterTree } from 'vuex';
import { ConditionCategoryState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ConditionCategoryState, RootState> = {
  ['getConditionCategorys']: (state) => state.conditionCategorys,
  ['getConditionCategorysIsLoading']: (state) => state.conditionCategorys?.isLoading,
  ['getConditionCategorysTotal']: (state) => state.conditionCategorys?.total,
  ['getConditionCategorysSearchParams']: (state) => state.conditionCategorys?.searchParams,
};
