
import { MutationTree } from 'vuex';
import { TblUpdateConditionState } from './types';
import Vue from 'vue';
import tblUpdateCondition, { TblUpdateCondition, Value } from '@/shared/model/tblUpdateCondition';

export const mutations: MutationTree<TblUpdateConditionState> = {
  setTblUpdateConditions(state, payload: Value) {
    state.tblUpdateConditions.items = payload.value.map((x) => tblUpdateCondition.parse(x));
    state.tblUpdateConditions.total = payload['@odata.count'] || 0;
    state.tblUpdateConditions.isLoading = false;
  },
  setTblUpdateConditionsTotal(state, payload: number) {
    state.tblUpdateConditions.total = payload;
  },
  setTblUpdateConditionsIsLoading(state, payload: boolean) {
    state.tblUpdateConditions.isLoading = payload;
  },
  setDashboardDate(state, payload: string) {
    state.dashboardDate = payload;
  },
  setDashboarCompanyWrGruppen(state, payload: any) {
    state.dashboarCompanyWrGruppen = payload;
  },
  setDashboardConditionsStatus(state, payload: any) {
    console.log('-----------------------set DashboardConditionsStatus----------------------------------------------------------------  ===>>> payload  ', payload);
    state.dashboardConditionsStatus = payload;
  },
  setGetConditionsVisible(state, payload: any) {
    state.conditionsVisible = payload;
  },
};
