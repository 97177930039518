
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import conditionCategory, { ConditionCategory, Value } from '../model/conditionCategory';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ConditionCategory');
export interface BackendConditionCategory {
  getConditionCategory(id: string): AxiosPromise<ConditionCategory>;
  getConditionCategorys: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteConditionCategory(id: string): AxiosPromise;
  updateConditionCategory(ConditionCategory: ConditionCategory): AxiosPromise<any>;
}

export const defaultBackendConditionCategory: BackendConditionCategory = {
  getConditionCategory(id: string): AxiosPromise<ConditionCategory> {
    let url = `${URLS.conditionCategoryOdata}/${id}`;
    return instance.get<ConditionCategory>(url);
  },
  getConditionCategorys(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.conditionCategoryOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getConditionCategorys${url}`);
    return instance.get<Value>(url);
  },

  deleteConditionCategory(id: string): AxiosPromise {
    logger.debug('deleteConditionCategory');
    return instance.delete(`${URLS.conditionCategory}/${id}`);
  },
  updateConditionCategory(ConditionCategory: ConditionCategory): AxiosPromise<any> {
    logger.debug('updateConditionCategory');
    return instance.put<ConditionCategory>(`${URLS.conditionCategory}/update`, conditionCategory.toAPI(ConditionCategory));
  },
};
