
import { MutationTree } from 'vuex';
import { ContactPanelState } from './types';
import Vue from 'vue';
import contactPanel, { ContactPanel, Value } from '@/shared/model/contactPanel';

export const mutations: MutationTree<ContactPanelState> = {
  setContactPanels(state, payload: Value) {
    state.contactPanels.items = payload.value.map((x) => contactPanel.parse(x));
    state.contactPanels.total = payload['@odata.count'] || 0;
    state.contactPanels.isLoading = false;
  },
  setContactPanelsTotal(state, payload: number) {
    state.contactPanels.total = payload;
  },
  setContactPanelsIsLoading(state, payload: boolean) {
    state.contactPanels.isLoading = payload;
  },
};
