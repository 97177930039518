import { Component, Prop, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { Contact } from '@/shared/model/contact';
import { namespace } from 'vuex-class';

const logger = new Logger('deactivate-dialog');
const contactModule = namespace('contact');
const authModule = namespace('auth');

@Component({
  name: 'deactivate-dialog',
  components: {},
})
export default class DeactivateDialog extends Vue {
  @contactModule.Action('deactivateContact')
  private actionDeactivateContact!: any;

  @authModule.Action('loadAzureUserId')
  private actionLoadAzureUserId!: any;
  @authModule.Action('deactivateUserOnAzure')
  private actionDeactivateUserOnAzure!: any;
  @authModule.Action('activateUserOnAzure')
  private actionActivateUserOnAzure!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop()
  private contactModel!: Contact;

  isSaving = false;
  contactAzureId: string | null = null;
  async clickDeactivate(): Promise<void> {
    logger.log('deactivate-user');

    this.isSaving = true;

    const userId = await this.actionLoadAzureUserId(this.contactModel.email)
      .then(async (result: any) => {
        const azureUserId = result;
        this.contactAzureId = azureUserId;

        // (AD-72) (discussed with Chaslau) Temporary comment Azure deactivate functionality (just update database)
        await this.updateIsActiveInDb(false);
        // await this.actionDeactivateUserOnAzure(azureUserId).then(async (resp: any) => {
        //   await this.updateIsActiveInDb(false);
        // });
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  async clickActivate(): Promise<void> {
    logger.log('activate-user');

    this.isSaving = true;

    const userId = await this.actionLoadAzureUserId(this.contactModel.email)
      .then(async (result: any) => {
        const azureUserId = result;
        this.contactAzureId = azureUserId;

        // (AD-72) (discussed with Chaslau) Temporary comment Azure activate functionality (just update database)
        await this.updateIsActiveInDb(true);
        // await this.actionActivateUserOnAzure(azureUserId).then(async (resp: any) => {
        //   await this.updateIsActiveInDb(true);
        // });
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  async updateIsActiveInDb(isActive: boolean): Promise<void> {
    const payload = {
      id: this.contactModel.recordID,
      isActive: isActive, // `false` means deactivate Contact, `true` activate
      contactAzureId: this.contactAzureId,
    };

    await this.actionDeactivateContact(payload).then(() => {
      this.contactModel.isActive = isActive;
      this.clickClose();
    });
  }

  clickClose(): void {
    this.$emit('click:close');
  }
}
