
import { ActionTree } from 'vuex';
import { GspModuleState } from './types';
import { RootState } from '../../types';
import { defaultBackendGspModule } from '@/shared/backend/gspModule';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import gspModule, { Value, GspModule } from '@/shared/model/gspModule';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.gspModules');
export const actions: ActionTree<GspModuleState, RootState> = {
  getGspModules({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, serviceId?: number }) {
    commit('setGspModulesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getGspModulesSearchParams;
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendGspModule
      .getGspModules(searchParams,payload?.serviceId)
      .then((response: AxiosResponse<Value>) => {
        commit('setGspModules', response.data);
        commit('setGspModulesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setGspModulesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getGspModule({ commit, dispatch }, id: string) {
    return defaultBackendGspModule
      .getGspModule(id)
      .then((response: AxiosResponse<GspModule>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateGspModule({ commit, dispatch }, file: GspModule) {
    return defaultBackendGspModule
      .updateGspModule(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspModule;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.gspModule_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteGspModule({ commit, dispatch }, id: string) {
    return defaultBackendGspModule
      .deleteGspModule(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspModule;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
