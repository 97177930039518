var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',[(_vm.isCorrectRoute)?_c('v-app-bar',{class:[_vm.isNotProductionEnv ? 'v-themeBlue' : 'v-themeWhite'],attrs:{"flat":"","app":"","clipped-left":""}},[(_vm.mobile)?_c('v-app-bar-nav-icon',{staticClass:"ml-0",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.drawer ? 'mdi-close' : 'mdi-menu')+" ")])],1):_vm._e(),_c('v-toolbar-title',{staticClass:"pl-0"},[_c('router-link',{attrs:{"tag":"div","to":"/","exact":""}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('img',{staticClass:"header__logo",attrs:{"src":require("@/assets/logo_neu.png"),"alt":"Logo"}}),_c('h4',{class:[_vm.isNotProductionEnv ? 'ml-4 Anthrazit--text text-uppercase font-weight-bold' : 'ml-4 Black--text text-uppercase font-weight-bold']},[_vm._v(_vm._s(_vm.$t(_vm.header) + _vm.headerTitle))])])],1)],1),_c('v-spacer'),(_vm.isLoggedIn)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('d4y-btn',_vm._g({staticClass:"ma-0 text-capitalize",attrs:{"text":""}},on),[_c('span',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))]),_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}],null,false,1557108998)},[_c('v-list',[(_vm.isLoggedIn && false)?_c('v-list-item',{attrs:{"to":"/editProfile"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('edit_profile'))+" ")])],1)],1):_vm._e(),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn),expression:"isLoggedIn"}],on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_c('v-list-item-action',[_c('v-icon',{staticClass:"action_logout"},[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('logout')))])],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.isCorrectRoute)?_c('v-navigation-drawer',{staticClass:"left-menu",attrs:{"permanent":!_vm.mobile,"app":"","clipped":"","mini-variant":_vm.toggleMini,"mini-variant-width":"115"},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.hasAzureUser)?_c('div',[(_vm.hasAzureUser)?_c('div',{staticClass:"Anthrazit-65--text"},[_vm._v(_vm._s(_vm.azureUser.name))]):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('d4y-btn',_vm._g({staticClass:"ma-0",attrs:{"text":""}},on),[_c('v-img',{attrs:{"contain":"","height":40,"max-height":40,"max-width":40,"src":require('@/assets/left_menu/ico_profile.svg')}})],1)]}}],null,false,3734522530)},[_c('v-list',[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAzureUser),expression:"hasAzureUser"}],on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_c('v-list-item-action',[_c('v-icon',{staticClass:"action_logout"},[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('logout')))])],1)],1)],1)],1):_vm._e(),_c('div',[_c('v-avatar',{staticClass:"change-lang__btn mt-2",class:[!_vm.mobile ? 'mb-80' : 'mb-4'],attrs:{"tile":"","width":"42","height":"30"},on:{"click":function($event){return _vm.setLocale(_vm.$i18n.locale =='de' ? 'en' : _vm.$i18n.locale =='en' ? 'nl' : 'de' )}}},[_c('v-img',{attrs:{"src":_vm.$i18n.locale =='de' ? require("../../assets/flags/de.svg") : _vm.$i18n.locale =='en' ? require("../../assets/flags/en.svg") : require("../../assets/flags/nl.svg"),"aspect-ratio":"1.0"}})],1)],1)]},proxy:true}],null,false,4268334831),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div'),_c('v-list',{staticClass:"px-2",attrs:{"nav":""}},_vm._l((_vm.leftMenuItems),function(item,i){return _c('div',{key:i},[(!!item)?_c('v-list-item',{class:{'v-list-item--active': _vm.isPartialRoute(item)},attrs:{"ripple":false,"link":"","to":item.to},on:{"click":function($event){return _vm.setHeader(item.translateId)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"d-flex flex-wrap justify-center"},[(i==0)?_c('v-icon',{staticClass:"mdi-flip-h",attrs:{"large":""}},[_vm._v("mdi-lock-open-outline")]):_vm._e(),(i==1)?_c('v-icon',{staticClass:"mdi-flip-h",attrs:{"large":""}},[_vm._v("mdi-lock-open-outline")]):_vm._e(),(i==2)?_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-key-variant")]):_vm._e(),(item.translateId)?_c('div',{staticClass:"full-width",class:[ _vm.isPartialRoute(item) || active ? 'white--text': 'Anthrazit-65--text']},[_vm._v(" "+_vm._s(_vm.$t(item.translateId))+" ")]):_vm._e()],1)]}}],null,true)}):_vm._e()],1)}),0)],1):_vm._e(),_c('v-main',[_c('router-view',{key:_vm.currentRoute})],1),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar.color,"timeout":_vm.snackbar.duration},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('d4y-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.releaseSnackbar()}}},[_vm._v("Ok")])],1),_c('dialogbox',{ref:"dialogBox"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }