import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { Value, UserActionsUserRoleChanges } from '@/shared/model/userActionsUserRoleChanges';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import D4yTable from '@/components/d4y-table/d4y-table.vue';

const userActionsUserRoleChangesModule = namespace('userActionsUserRoleChanges');

@Component({ components: { D4yTable } })
export default class ChangeLogInfoTableDialog extends Vue {
  @userActionsUserRoleChangesModule.Action('getUserActionsUserRoleChanges')
  private actionGetUserActionsUserRoleChanges!: (contactId: string) => Promise<Value>;
  @userActionsUserRoleChangesModule.Getter('getUserActionsUserRoleChanges')
  private userActionsUserRoleChanges!: OdataItems<UserActionsUserRoleChanges>;
  @userActionsUserRoleChangesModule.Getter('getUserActionsUserRoleChangesIsLoading')
  private userLogIsLoading!: boolean;
  @userActionsUserRoleChangesModule.Getter('getUserActionsUserRoleChangesTotal')
  private userLogTotal!: number;
  @userActionsUserRoleChangesModule.Getter('getUserActionsUserRoleChangesSearchParams')
  private userLogSearchParams!: SearchParams;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop()
  private contactId!: string;

  private async getUserActionsUserRoleChanges() {
    await this.actionGetUserActionsUserRoleChanges(this.contactId);
  }

  private onClickClose() {
    this.$emit('click:close');
  }

  private async userLogUpdateOptions(newVal: any, oldVal: any) {
    this.userLogSearchParams.dataOption = newVal;
    this.userLogSearchParams.filter = newVal.filter;
    if (newVal != oldVal && !this.userLogIsLoading) this.getUserActionsUserRoleChanges();
  }

  private get headers(): {
    text: string | any;
    value: string;
    sortable?: boolean;
    width?: string;
  }[] {
    return [
      { text: this.$tc('action'), value: 'user_action_type' },
      { text: this.$tc('service'), value: 'service_name' },
      { text: this.$tc('role'), value: 'role_name' },
      { text: this.$tc('executed_by'), value: 'erstellt_von_email' },
      { text: this.$tc('executed_on'), value: 'erstellt_am' },
    ];
  }
}
