
import { MutationTree } from 'vuex';
import { GspAttributeState } from './types';
import Vue from 'vue';
import gspAttribute, { GspAttribute, Value } from '@/shared/model/gspAttribute';

export const mutations: MutationTree<GspAttributeState> = {
  setGspAttributes(state, payload: Value) {
    state.gspAttributes.items = payload.value.map((x) => gspAttribute.parse(x));
    state.gspAttributes.total = payload['@odata.count'] || 0;
    state.gspAttributes.isLoading = false;
  },
  setGspAttributesTotal(state, payload: number) {
    state.gspAttributes.total = payload;
  },
  setGspAttributesIsLoading(state, payload: boolean) {
    state.gspAttributes.isLoading = payload;
  },
};
