
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import gspModule, { GspModule, Value } from '../model/gspModule';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.GspModule');
export interface BackendGspModule {
  getGspModule(id: string): AxiosPromise<GspModule>;
  getGspModules: (searchParams: SearchParams, serviceId?: number) => AxiosPromise<Value>;
  deleteGspModule(id: string): AxiosPromise;
  updateGspModule(GspModule: GspModule): AxiosPromise<any>;
}

export const defaultBackendGspModule: BackendGspModule = {
  getGspModule(id: string): AxiosPromise<GspModule> {
    let url = `${URLS.gspModule}/${id}`;
    return instance.get<GspModule>(url);
  },
  getGspModules(searchParams: SearchParams,serviceId?: number): AxiosPromise<Value> {
    // console.log('serviceId?: number :>> ', serviceId);
    var odfb = ODataFilterBuilder('and');
    if (serviceId) {
      odfb.eq('serviceId', serviceId, false);
    }
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.gspModuleOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=Service($select=name,short_common_text)']//,'$select=name']
    );
    url=url.replace('service.name','service/name')
    logger.log(`getGspModules${url}`);
    return instance.get<Value>(url);
  },

  deleteGspModule(id: string): AxiosPromise {
    logger.debug('deleteGspModule');
    return instance.delete(`${URLS.gspModule}/${id}`);
  },
  updateGspModule(GspModule: GspModule): AxiosPromise<any> {
    logger.debug('updateGspModule');
    return instance.put<GspModule>(`${URLS.gspModule}/update`, gspModule.toAPI(GspModule));
  },
};
