
import { GetterTree } from 'vuex';
import { GspModuleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<GspModuleState, RootState> = {
  ['getGspModules']: (state) => state.gspModules,
  ['getGspModulesIsLoading']: (state) => state.gspModules?.isLoading,
  ['getGspModulesTotal']: (state) => state.gspModules?.total,
  ['getGspModulesSearchParams']: (state) => state.gspModules?.searchParams,
};
