import { MutationTree } from 'vuex';
import { UserActionsUserRoleChangesState } from './types';
import userActionsUserRoleChanges, { Value } from '@/shared/model/userActionsUserRoleChanges';

export const mutations: MutationTree<UserActionsUserRoleChangesState> = {
  setUserActionsUserRoleChanges(state, payload: Value) {
    state.userActionsUserRoleChanges.items = payload.value.map((x) => userActionsUserRoleChanges.parse(x));
    state.userActionsUserRoleChanges.total = payload['@odata.count'] || 0;
    state.userActionsUserRoleChanges.isLoading = false;
  },
  setUserActionsUserRoleChangesTotal(state, payload: number) {
    state.userActionsUserRoleChanges.total = payload;
  },
  setUserActionsUserRoleChangesIsLoading(state, payload: boolean) {
    state.userActionsUserRoleChanges.isLoading = payload;
  },
  resetUserActionsUserRoleChangesDataOption(state) {
    state.userActionsUserRoleChanges.searchParams.filter = '';
    state.userActionsUserRoleChanges.searchParams.dataOption.filter = '';
    state.userActionsUserRoleChanges.searchParams.dataOption = {
      page: 1,
      itemsPerPage: 5,
      sortBy: ['erstellt_am'],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false,
    };
  },
};
