
export interface ConditionCategory {
    id: number; // int  
    name: string; // string  
 //   contactPanels: System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.ContactPanelDm>; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.ContactPanelDm>  
}

export interface Value {
  value: ConditionCategory[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ConditionCategory>): any {
  return {
    Id: data?.id || 0,  
    Name: data?.name || '',  
   // ContactPanels: data?.contactPanels || '',  
  };
}

function parse(data?: Partial<ConditionCategory>): ConditionCategory {
  return {
    id: data?.id || 0,  
    name: data?.name || '',  
    //contactPanels: data?.contactPanels || '',  
  };
}

export default {
  parse,
  toAPI: toAPI,
};
