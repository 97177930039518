
export interface GspRoleData {
    id: number; // uint
    roleId: number; // uint
    role: any;// adminPanel.DataAccessPostgreSQL.Database.DataModel.GspRoleDm;
    serviceId: number; // uint
    service: any; // adminPanel.DataAccessPostgreSQL.Database.DataModel.GspServiceDm
    moduleId: number; // uint
    module:any; // adminPanel.DataAccessPostgreSQL.Database.DataModel.GspModuleDm
    attributes:any;//  System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspAttributeDm>
    created: string; // System.DateTime?
    creatorId: string; // string
    creatorEmail: string; // string

}

export interface Value {
  value: GspRoleData[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GspRoleData>): any {
  return {
    Id: data?.id || 0,
    RoleId: data?.roleId || 0,
    Role: data?.role || undefined,
    ServiceId: data?.serviceId || 0,
    Service: data?.service || undefined,
    ModuleId: data?.moduleId || 0,
    Module: data?.module || undefined,
    Attributes: data?.attributes || undefined,
    Created: data?.created || undefined,
    CreatorId: data?.creatorId || undefined,
    CreatorEmail: data?.creatorEmail || undefined,
  };
}

function parse(data?: Partial<GspRoleData>): GspRoleData {
  return {
    id: data?.id || 0,
    roleId: data?.roleId || 0,
    role: data?.role || '',
    serviceId: data?.serviceId || 0,
    service: data?.service || '',
    moduleId: data?.moduleId || 0,
    module: data?.module || '',
    attributes: data?.attributes || '',
    created: data?.created || '',
    creatorId: data?.creatorId || '',
    creatorEmail: data?.creatorEmail || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
