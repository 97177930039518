
import { ActionTree } from 'vuex';
import { DocumentTypeState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocumentType } from '@/shared/backend/documentType';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import documentType, { Value, DocumentType } from '@/shared/model/documentType';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.documentTypes');
export const actions: ActionTree<DocumentTypeState, RootState> = {
  getDocumentTypes({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setDocumentTypesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getDocumentTypesSearchParams;
    let organisationId = CONST.emptyGuid;
    return defaultBackendDocumentType
      .getDocumentTypes(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentTypes', response.data);
        commit('setDocumentTypesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentTypesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentType({ commit, dispatch }, id: string) {
    return defaultBackendDocumentType
      .getDocumentType(id)
      .then((response: AxiosResponse<DocumentType>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDocumentType({ commit, dispatch }, file: DocumentType) {
    return defaultBackendDocumentType
      .updateDocumentType(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentType;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.documentType_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDocumentType({ commit, dispatch }, id: string) {
    return defaultBackendDocumentType
      .deleteDocumentType(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentType;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
