import { GetterTree } from 'vuex';
import { UserActionsUserRoleChangesState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<UserActionsUserRoleChangesState, RootState> = {
  ['getUserActionsUserRoleChanges']: (state) => state.userActionsUserRoleChanges,
  ['getUserActionsUserRoleChangesIsLoading']: (state) => state.userActionsUserRoleChanges?.isLoading,
  ['getUserActionsUserRoleChangesTotal']: (state) => state.userActionsUserRoleChanges?.total,
  ['getUserActionsUserRoleChangesSearchParams']: (state) => state.userActionsUserRoleChanges?.searchParams,
};
