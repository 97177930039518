import { AxiosPromise } from 'axios';
import { instance } from '.';
import { Credentials } from '../model/smallPayloadModels/credentials';
import { URLS } from './index';
import AuthUtils from '../utils/authUtils';
import GeneralUtils from '../utils/generalUtils';


export const vmeSupportGroup = AuthUtils.getVmeSupportGroup().name;
export const vmeSupportGroupId = AuthUtils.getVmeSupportGroup().groupId;
export const vmeGlobalAdminGroup = AuthUtils.getVmeGlobalAdminGroup().name;
export const vmeGlobalAdminGroupId = AuthUtils.getVmeGlobalAdminGroup().groupId;
export const vmeSingleCompanyAdminGroup =  AuthUtils.getVmeSingleCompanyAdminGroup().name;
export const vmeSingleCompanyAdminGroupId = AuthUtils.getVmeSingleCompanyAdminGroup().groupId;

export const vmePortalGroup = 'aad-app-vme-portal';
export const vmePortalGroupId = 'ab778d74-cc6b-4c20-a9fe-6845f776284d';
// AD-130 Haus-Admin groupId
export const vmeHausAdminGroupId = '3193d9d0-36c4-4e31-ae6e-c011405301e9';


export interface BackendAccount {
  authenticate: (
    username: string,
    password: string,
    rememberMe?: boolean
  ) => AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }>;

  getMyAccount: () => AxiosPromise<any>;
  logout: () => AxiosPromise;
  getVmeSupplierData: (id: string) => AxiosPromise;
  getVmeSupplierUmsatz: (data: any) => AxiosPromise;
  getVmeApiUserId: (email: string) => AxiosPromise;

  loadAzureUserId: (email: string) => AxiosPromise<any>;
  deactivateUserOnAzure: (id: string) => AxiosPromise<any>;
  activateUserOnAzure: (id: string) => AxiosPromise<any>;

  // (AD-126) used `email` for `isInGroup` since `ContactAzureId` is often empty
  isInGroup: (email: string) => AxiosPromise<any>;
  canSeeAllCompanyContacts: (id: string) => AxiosPromise<any>;

  addInAzureHausAdminGroup: (payload: any) => AxiosPromise<any>;
  getEnvironment():  AxiosPromise<any>;
}

export const defaultBackendAccount: BackendAccount = {
  authenticate(
    username: string,
    password: string,
    rememberMe?: boolean
  ): AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }> {
    const credentials: Credentials = {
      password,
      username,
      rememberMe,
      authorities: [],
      emailConfirmed: false,
      inactivityMinutes: 0,
    };
    return instance.post<any>(`${URLS.authenticate}`, credentials);
  },

  getMyAccount(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.account}/GetMe`);
  },
  logout(): AxiosPromise {
    return instance.post(`${URLS.account}/logout`);
  },
  getVmeSupplierData(id: string): AxiosPromise {
    return instance.put(`${URLS.users}/GetVmeSupplier`, `"${id}"`);
  },
  getVmeSupplierUmsatz(data: any): AxiosPromise {
    return instance.put(`${URLS.users}/GetVmeSupplierUmsatz`, data);
  },
  getVmeApiUserId(email: string): AxiosPromise {
    return instance.put(`${URLS.users}/GetVmeUserIdByEmail`, `"${email}"`);
  },
  // getVmeApiUserId: (email: string) => AxiosPromise;

  loadAzureUserId(email: string): AxiosPromise<any> {
    return instance.get<any>(`${URLS.msGraphUsers}?$filter=mail eq '${email}'`);
  },
  deactivateUserOnAzure(azureUserId: string): AxiosPromise<any> {
    return instance.patch<any>(`${URLS.msGraphUsers}/${azureUserId}`, {
      accountEnabled: false,
    });
  },
  activateUserOnAzure(azureUserId: string): AxiosPromise<any> {
    return instance.patch<any>(`${URLS.msGraphUsers}/${azureUserId}`, {
      accountEnabled: true,
    });
  },
  isInGroup(azureEmail: string): AxiosPromise<any> {
    if (GeneralUtils.isLocalDevEnv()) {
      // (AD-126) for local test with my Azure group
      // https://stackoverflow.com/questions/48872132/microsoft-graph-query-is-user-member-of-group
      // https://learn.microsoft.com/en-us/graph/api/group-list-members?view=graph-rest-1.0&tabs=http#code-try-1
      // azureEmail = 'denis@eta-com-it.com'
      // (2024-09) (AD-168) got an `Insufficient privileges error` so added `Group.ReadWrite.All` Application rules + grant admin consent + relogin (to get new MSAL token)
      // if doesn't help try also `GroupMember.ReadWrite.All` role (Application )
      // link to azure rights for project: https://aad.portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/CallAnAPI/appId/591cf799-7461-4f0b-bc5f-5044e5684126/isMSAApp~/false
      return instance.get<any>(
        `https://graph.microsoft.com/v1.0/groups/${vmeSingleCompanyAdminGroupId}/members/$count?$filter=mail eq '${azureEmail}'`
      );
    } else {
      return instance.get<any>(`https://graph.microsoft.com/v1.0/groups/${vmePortalGroupId}/members/$count?$filter=mail eq '${azureEmail}'`
      );
    }

  },
  canSeeAllCompanyContacts(azureUserId: string): AxiosPromise<any> {
    console.log('vmeGlobalAdminGroup :>> ', vmeGlobalAdminGroup);
    return instance.get<any>(
      `https://graph.microsoft.com/v1.0/me/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$search="displayName:${vmeGlobalAdminGroup}" OR "displayName:${vmeSupportGroup}" OR "displayName:${vmeSingleCompanyAdminGroup}"&$select=displayName,id`
    );
  },

  // (AD-130) for local test of `addInAzureHausAdminGroup` use next Azure GroupId(`vme-admin3`) `a3fde24e-5d5e-4d99-811d-0c382cd902a3` + my  Azure id `dfc4af64-9a8f-43ea-b8ab-5fb19bbbdecd``
  addInAzureHausAdminGroup(data: any): AxiosPromise<any> {

    return instance.put(`${URLS.contact}/AddRemoveInAzureHausAdminGroup`, data);

    // console.log('addInAzureHausAdminGroup :>> ', data);


    if (data.isHouseAdmin) {
      return instance.post<any>(`https://graph.microsoft.com/v1.0/groups/${vmeHausAdminGroupId}/members/$ref`, {
        "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${data.azureUserId}`
      }
      );
    } else {
      return instance.delete<any>(`https://graph.microsoft.com/v1.0/groups/${vmeHausAdminGroupId}/members/${data.azureUserId}/$ref`);
    }
  },
  getEnvironment(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.account}/GetEnvironmentName`);
  },
};
